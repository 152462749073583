import React, { useEffect, useState, forwardRef } from "react";

import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import pwa_image from "../../assets/Images/pwa_image.png";
import lock from "../../assets/Images/lock.png";
import arrow_right from "../../assets/Images/arrow_right.png";
import { SessionUpdateRequest, RequestBody } from "../../types/types";
import { useQueryClient } from "react-query";
import { Config } from "../../Utils/AppConstants";
import { makeApiCall } from "../../services/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  API_CALL_TYPE,
  POST_PAYMENT_API,
  POST_PAYMENT_METHOD,
  UPDATE_SESSION,
} from "../../services/Api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LoaderComponent from "../../components/LoaderComponent";
import { ToastContentProps, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SessionState, setSessionData } from "../../redux/sessionSlice";
import DOMPurify from "dompurify";

const PaymentForm = () => {
  const minDate = new Date();
  const [isCardSelected, setIsCardSelected] = React.useState(true);
  const queryClient = useQueryClient();
  const [dueAmount, setDueAmount] = useState(0); // Initial monthly due amount
  const [expanded, setExpanded] = useState(false);
  const [isYearly, setisYearly] = useState("yearly");
  const [isPast12, setisPast12] = useState(false);
  const [isPast24, setisPast24] = useState(false);
  const [isHome, setisHome] = useState(false);
  const [pastoneTime, setpastoneTime] = useState("");
  const [homeDue, sethomeDue] = useState("");
  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccessMessage, setPaymentSuccessMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false); // State to control the visibility of the loader
  const dispatch = useDispatch();
  const [expirationDate, setExpirationDate] = useState<Date | null>(null); //   cardNumber: "",
  const initialFormState = {
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    bankname: "",
    cardholderName: "",
    accountNumber: "",
    routingNumber: "",
    signature: "",
  };

  const sanitizeInput = (input: string | Node) => {
    return DOMPurify.sanitize(input);
  };
  const [formErrors, setFormErrors] = useState(initialFormState);
  const [formData, setFormData] = useState(initialFormState);
  const CustomDatePickerInput = forwardRef(
    (
      {
        value = "",
        onClick = () => {},
        onChange = () => {},
        placeholder = "Expiration Date",
      }: { value?: any; onClick?: any; onChange?: any; placeholder?: string },
      ref
    ) => (
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        onChange={onChange}
        className="bg-transparent border-10 my-2 poppins-light placeholder-white text-white w-full mb-2 px-3 border-white py-3 rounded-md"
        style={{ borderColor: "white" }}
      />
    )
  );

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false,
    duration: number = 3000
  ) => {
    const toastType = isError ? toast.error : toast.success;

    toastType(message, {
      position: "top-right",
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543",
        color: "white",
        fontFamily: "Poppins",
        maxWidth: "400px", // Adjust as needed
        whiteSpace: "normal", // Allow text to wrap
        wordWrap: "break-word", // Break long words
      },
      bodyClassName: "poppins-font toast-message", // Add class for overflow handling
    });
  };

  // CSS
  // .toast-message {
  //   overflow: hidden; /* Prevent overflow from the container */
  //   text-overflow: ellipsis; /* Optional: adds ellipsis for overflowed text */
  // }

  useEffect(() => {
    setShowLoader(isLoading); // Show loader when isLoading is true
  }, [isLoading]);

  useEffect(() => {
    // Set the current date using Moment.js
    const currentDate = moment().toDate();
    setExpirationDate(currentDate);
  }, []); // Run only once when the component mounts

  const maskCVV = (cvv: string) => {
    return cvv.replace(/./g, "*"); // Mask all characters
  };

  // Event handler for expiration date change

  const handleDateChange = (date: Date | null, name: string) => {
    setExpirationDate(date);
    if (date !== null) {
      setFormData({
        ...formData,
        [name]: date,
      });

      validateField(name, date.toISOString());
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value: inputValue } = e.target;
    let updatedValue = inputValue; // Initialize updated value with the input value

    // Check if the entered value exceeds 16 characters for CVV input
    if (name === "cvv" && inputValue.length > 4) {
      // If the entered value exceeds 16 characters, truncate it to 16 characters
      updatedValue = inputValue.slice(0, 16);
    }

    // CVV validation regex for a 3 or 4 digit number
    const cvvRegex = /^\d{3,4}$/;

    setFormData({
      ...formData,
      [name]: updatedValue, // Update the form data with the truncated value
    });

    validateField(name, updatedValue); // Validate the field on change

    if (name === "cardNumber") {
      updatedValue = formatCardNumber(inputValue); // Format card number
    }

    setFormData({
      ...formData,
      [name]: updatedValue, // Update the form data with the truncated value
    });

    validateField(name, updatedValue); // Validate the field on change
  };

  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");

  useEffect(() => {
    if (updatedsessionData) {
      const sessionPages = updatedsessionData.data.sessionPages;
      if (sessionPages && sessionPages.length >= 2) {
        const pageData = JSON.parse(sessionPages[2].pageData);
        const subscriptionTypeValue = pageData.SubscriptionType;
        const PastRentReporting12 = pageData.PastRentReporting12;
        const PastRentReporting24 = pageData.PastRentReporting24;
        const CurrentRentReporting = pageData.CurrentRentReporting;
        let currentRentValue = 0;
        let pastRentValue = 0;

        if (
          subscriptionTypeValue &&
          subscriptionTypeValue.toLowerCase() === "yearly"
        ) {
          setisYearly("Yearly");
          if (PastRentReporting12) {
            pastRentValue = 49;
            setisPast12(true);
            setpastoneTime("$49");
          }
          if (PastRentReporting24) {
            pastRentValue = 65;
            setpastoneTime("$65");
            setisPast24(true);
          }
          if (CurrentRentReporting) {
            currentRentValue = 49;
            setisHome(true);
            sethomeDue("$49");
          }
          const dueAmount = pastRentValue + currentRentValue;

          setDueAmount(dueAmount);
        } else if (
          subscriptionTypeValue &&
          subscriptionTypeValue.toLowerCase() === "monthly"
        ) {
          setisYearly("Monthly");
          if (PastRentReporting12) {
            setisPast12(true);
            pastRentValue = 49;
            setpastoneTime("$49");
          }
          if (PastRentReporting24) {
            pastRentValue = 65;
            setisPast24(true);
            setpastoneTime("$65");
          }
          if (CurrentRentReporting) {
            currentRentValue = 6.95;
            setisHome(true);
            sethomeDue("$6.95");
          }
        }

        const dueAmount = pastRentValue + currentRentValue;
        setDueAmount(dueAmount);
      } else {
        console.error(
          "sessionPages array is missing or doesn't have enough items."
        );
      }
    } else {
      console.error("updatedsessionData is undefined or null.");
    }
  }, [updatedsessionData]);

  const validateField = (name: string, value: string) => {
    switch (name) {
      case "cardNumber":
        const cardNumberRegex =
          /^(\d{4}-\d{4}-\d{4}-\d{4}|\d{4}-\d{4}-\d{4}-\d{3})$/;

        if (!cardNumberRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cardNumber:
              "Invalid card number. Please enter a valid 16-digit card number.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cardNumber: "",
          }));
        }
        break;
      // Add case
      case "expirationDate":
        // Expiration date validation (assuming it's a valid date)
        if (!value) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            expirationDate: "Expiration date is required.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            expirationDate: "", // Reset error if validation passes
          }));
        }
        break;
      case "cvv":
        // CVV validation
        const cvvRegex = /^\d{3,4}$/; // 3 or 4 digits for CVV
        if (!cvvRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cvv: "Invalid CVV.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cvv: "", // Reset error if validation passes
          }));
        }
        break;

      case "cardholderName":
        // Cardholder name validation
        if (!value.trim()) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cardholderName: "Cardholder name is required.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            cardholderName: "", // Reset error if validation passes
          }));
        }

        break;

      case "bankname": // Change "Bankname" to "bankname"
        // Bank Name validation
        if (!value.trim()) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            bankname: "Bank name is required.", // Change "Bankname" to "bankname"
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            bankname: "", // Reset error if validation passes
          }));
        }
        break;
      case "accountNumber":
        // Account Number validation
        if (!value.trim()) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            accountNumber: "Account Number is required.",
          }));
        } else if (value.trim().length > 17) {
          // Length check
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            accountNumber: "Account Number should not exceed 17 characters.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            accountNumber: "", // Reset error if validation passes
          }));
        }
        break;

      case "routingNumber":
        // Routing Number validation

        if (!value.trim()) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            routingNumber: "Routing Number is required.",
          }));
        } else if (value.trim().length > 9) {
          // Length check
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            routingNumber: "Routing Number should not exceed 9characters.",
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            routingNumber: "", // Reset error if validation passes
          }));
        }
        break;
      // Add cases for other fields...
      default:
        break;
    }
  };

  const clearError = (fieldName: any) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "", // Clear the error message for the specified field
    }));
  };

  const updateSession = async (
    updatedSessionData: SessionUpdateRequest,
    paymentRequestResponse: any,
    successMessage: string // New parameter for success message
  ) => {
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-CRB",
      pageCount: updatedSessionData.pageCount,
      status: paymentRequestResponse?.status == "200" ? "Completed" : "Error",
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: Config.sessionID,
          pageNumber: "3",
          pageName: "PaymentScreen",
          pageTitle: "PaymentScreen",
          pageDescription: "Description of PaymentScreen",
          pageUrl: "/payment",
          pageStatus:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageStatusMessage:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageData: JSON.stringify({
            PaymentData: paymentRequestResponse,
          }),
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse(successMessage), // Pass the success message
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  const handleUpdateSessionResponse = (successMessage: string) => {
    return (response: SessionUpdateRequest) => {
      console.log("Updated here:", JSON.stringify(response));
      setIsLoading(false);

      if (response?.status === "400") {
        console.error("Invalid session error:", response.message);
        showCustomToast("Invalid session. Please start again.", true);
        navigate("/error", { state: "Invalid Session" });
        dispatch(setSessionData(response));
      } else {
        dispatch(setSessionData(response));
        queryClient.setQueryData("updatedSessionData", response);

        navigate("/done", { state: successMessage }); // Use the passed message here
      }
    };
  };

  const handleUpdateSessionError = (error: any) => {
    setIsLoading(false);

    console.error("Error updating session:", error);
    // Handle error here
  };
  const handlePostPaymentResponse = async (response: any) => {
    setIsLoading(false);

    if (response?.status === 400 || response?.status === 500) {
      showCustomToast(response?.message, true);
      return;
    }

    try {
      let dataToUpdate = UpdatedLocalData || updatedsessionData;

      if (dataToUpdate) {
        const message = response?.message; // Capture the message to pass
        setPaymentSuccessMessage(message);
        showCustomToast(response?.data?.message, false);

        if (
          response?.data?.message ===
          "Credit Rent Boost Tenant Already Registered. Payment Already Received."
        ) {
          const message = response?.data?.message; // Capture the message to pass
          await updateSession(dataToUpdate, response, message); // Pass the message here
        } else {
          await updateSession(dataToUpdate, response, message); // Pass the message here
        }

        if (
          response?.data?.message ===
          "Credit Rent Boost Tenant Already Registered. Payment Already Received."
        ) {
          showCustomToast(response?.data?.message, false, 10000);
          setPaymentSuccessMessage(response?.data?.message);
          return;
        }
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      console.error("Error updating session:", error);
      showCustomToast("An error occurred while updating the session.", true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostPaymentError = (error: any) => {
    setIsLoading(false);

    console.error("Error updating session:", error);
    // Handle error here
  };

  const paymentRequest = async () => {
    if (isCardSelected) {
      // If credit card is selected, validate card fields
      if (!formData.cardNumber || !formData.cvv || !formData.cardholderName) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          cardNumber: !formData.cardNumber ? "Card number is required." : "",
          expirationDate: !expirationDate ? "Expiration date is required." : "",
          cvv: !formData.cvv ? "CVV is required." : "",
          cardholderName: !formData.cardholderName
            ? "Cardholder name is required."
            : "",
        }));
        return;
      }

      if (expirationDate == null) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          expirationDate: "Expiration date is required.",
        }));
        return;
      }

      // Sanitize card number by removing dashes and spaces
      const sanitizedCardNumber = formData.cardNumber.replace(/[-\s]/g, "");

      // Check if the card number is either 15 or 16 digits long
      if (
        sanitizedCardNumber.length !== 15 &&
        sanitizedCardNumber.length !== 16
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          cardNumber: "Card number must be either 15 or 16 digits long.",
        }));
        return;
      }
    } else {
      // If bank account is selected, validate bank account fields
      if (!formData.accountNumber || !formData.routingNumber) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          accountNumber: !formData.accountNumber
            ? "Account number is required."
            : "",
          bankname: !formData.bankname ? "Bank name is required." : "",
          routingNumber: !formData.routingNumber
            ? "Routing number is required."
            : "",
        }));
        return;
      }
    }

    if (!isCardSelected) {
      // Check if account number is longer than 10 characters
      if (formData.accountNumber.length < 6) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          accountNumber: "Account number must be at least 6 characters long.",
        }));
        return;
      }

      // Check if routing number is not exactly 9 characters
      if (formData.routingNumber.length !== 9) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          routingNumber: "Routing number must be 9 characters long.",
        }));
        return;
      }
    }

    // Proceed with form submission logic
    setIsLoading(true);
    const maskedCVV = maskCVV(formData.cvv); // Mask the CVV
    const expirationMonth =
      isCardSelected && expirationDate
        ? String(expirationDate.getMonth() + 1).padStart(2, "0") // Format month as two digits
        : null;
    const expirationYear =
      isCardSelected && expirationDate ? expirationDate.getFullYear() : null;
    const sanitizedCardNumber = formData.cardNumber.replace(/-/g, "");

    try {
      let products = []; // Initialize products array

      // Check if isPast12 or isHome are true
      if (isPast12 && isHome) {
        products.push("Current and Past12");
      }

      // Check if isPast24 or isHome are true
      if (isPast24 && isHome) {
        products.push("Current and Past24");
      }

      // Check if only isPast12 is true
      if (isPast12 && !isPast24 && !isHome) {
        products = ["Past12 Only"];
      }

      // Check if only isPast24 is true
      if (!isPast12 && isPast24 && !isHome) {
        products = ["Past24Only"];
      }

      // Check if only isHome is true
      if (!isPast12 && !isPast24 && isHome) {
        products = ["Current"];
      }

      const expirationMonth =
        isCardSelected && expirationDate
          ? String(expirationDate.getMonth() + 1).padStart(2, "0") // Format month as two digits
          : null;
      const expirationYear =
        isCardSelected && expirationDate ? expirationDate.getFullYear() : null;

      // Format the products array as required
      const formattedProducts = products.join(" and ");
      const sanitizedCardNumber = formData.cardNumber.replace(/-/g, "");
      const currentDate = new Date();

      // Initialize variables for next billing date
      let nextBillingDate;

      // Check if billing cycle is yearly
      if (isYearly === "Yearly") {
        // If yearly, calculate next year's billing date
        nextBillingDate = new Date(
          currentDate.getFullYear() + 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
      } else {
        // If monthly, calculate next month's billing date
        nextBillingDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );
      }

      // Format recurringStartDate to ISO 8601 string
      const formattedRecurringStartDate = nextBillingDate.toISOString();
      const requestBody: RequestBody = {
        products: [formattedProducts],
        billingDetailRequestModel: {
          billingAmount:
            typeof dueAmount === "string" && dueAmount !== null
              ? Number(dueAmount) || dueAmount
              : Number(dueAmount),

          comment: "Payment",
          isRecurring: isHome ? true : false,
          RecurringAmount: isHome ? homeDue.replace(/\$/g, "") : null,
          CardHolderName: isCardSelected ? formData?.cardholderName : null,
          isSubscription: false,
          consumerSignature: null,
          cardType: isCardSelected ? "Master Card" : null,
          autoPay: false,
          recurringStartDate: formattedRecurringStartDate,
          billingEmail: null,
          paymentMethodId: null,
          BillingCycle: isYearly == "Yearly" ? "Annually" : "Monthly",
          paymentMethodCode: null,
          isAcceptTerms: true,
          paymentType: isCardSelected ? "CC" : "ACH",
          bankAccountNumber: isCardSelected ? null : formData?.accountNumber,
          bankRoutingNumber: isCardSelected ? null : formData?.routingNumber,
          bankName: isCardSelected ? formData?.bankname : null,
          cardNumber: isCardSelected ? sanitizedCardNumber : null,
          expiryMonth: isCardSelected
            ? (expirationMonth ?? "").toString()
            : null,
          expiryYear: isCardSelected ? (expirationYear ?? "").toString() : null,
          firstName: null,
          middleName: null,
          lastName: null,
          CVV: isCardSelected ? formData?.cvv : null,
        },
      };

      const response = await makeApiCall(
        API_CALL_TYPE.POST_CALL,
        POST_PAYMENT_API(),
        handlePostPaymentResponse,
        handlePostPaymentError,
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        requestBody
      );

      // Handle response if needed
    } catch (error) {
      setIsLoading(false);
      // Handle error if needed
    }
  };

  const handleSubmit = async () => {
    await paymentRequest();
  };

  const handlePaymentMehodResponse = async (response: any) => {
    setIsLoading(false);
    // showCustomToast(response?.message, false);
    await paymentRequest();
  };

  const handlePaymentMethodError = (error: any) => {
    console.error("Error updating session:", error);
    setIsLoading(false);
    showCustomToast(error?.message, false);

    // Handle error here
  };

  function handleClick() {
    // Check if dynamicLink is defined and not empty
    navigate("/home", { state: "fromPayment" });
  }

  const handleSelectionChange = (
    isCard: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsCardSelected(isCard);
    // Reset form data and errors
    setFormData(initialFormState);
    setFormErrors(initialFormState);
  };

  const formatCardNumber = (value: string) => {
    const sanitizedValue = value.replace(/\D/g, ""); // Remove non-digit characters
    const formattedValue = sanitizedValue.match(/.{1,4}/g)?.join("-") || ""; // Insert hyphens every 4 digits
    return formattedValue.slice(0, 19); // Ensure the maximum length is 19 characters
  };

  return (
    <div className="relative flex flex-col items-center w-full  bg-theme min-h-screen">
      {isLoading && <LoaderComponent />}{" "}
      <div className="relative bg-theme flex flex-col justify-between  py-10 text-gray-100 max-w-3xl mx-auto  ">
        <div className="flex items-center justify-between">
          <div
            onClick={handleClick}
            className="flex items-center p-4 space-x-4"
          >
            <img
              className="w-6 h-6 sm:w-8 sm:h-8"
              src={arrow_back_ios_new}
              alt="Back"
            />
            <p className="text-sm due-font-service sm:text-base poppins-bold text-gray-50">
              return to service selection
            </p>
          </div>
        </div>

        <h2 className="text-white due-font-service  text-center poppins-thin mt-10 mb-4  ">
          Improve my score!
        </h2>

        <div className="flex flex-col">
          <div
            className=" p-3 px-4 rounded-xl w-[85%] mx-auto mb-4 custom-div "
            onClick={toggleExpand}
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-white poppins-extralight  past12">
                Purchase Summary
              </span>
              <div className="flex items-center">
                {expanded ? null : (
                  <span className="text-white poppins-semibold purchasedue">
                    {isCardSelected ? `$${dueAmount}` : `$${dueAmount}`}
                  </span>
                )}

                <img
                  src={arrow_right}
                  className="w-[10px] h-[16px] ml-3"
                  alt="Arrow Right"
                />
              </div>
            </div>

            {/* Additional content when expanded */}
            {expanded && (
              <div className=" p-3 px-4 rounded-xl  mx-auto mb-4">
                <div className="flex flex-col">
                  <div className="flex  ">
                    <span className="text-white  due-font-main poppins-bold">
                      {`$${dueAmount}`}
                    </span>
                    <span className="text-white due-font-main1 poppins-regular mx-2 mt-1">
                      due today
                    </span>
                    <span className="text-white font-poppins font-medium">
                      {/* Calculate and display due amount for today */}
                    </span>
                  </div>

                  {isPast12 || isPast24 ? (
                    <div className="my-6">
                      <div className="flex font-poppins mx-3">
                        <span className="text-white poppins-regular creporting">
                          {pastoneTime}
                        </span>
                        <span className="text-white due-font-main1  poppins-regular mx-2">
                          one-time
                        </span>
                        <span className="text-white font-medium">
                          {/* Calculate and display due amount for today */}
                        </span>
                      </div>

                      {isPast12 ? (
                        <div className="text-white main12  poppins-extralight mx-3">
                          Past 12 months of rent reporting
                        </div>
                      ) : (
                        <div className="text-white main12  poppins-extralight mx-3">
                          Past 24 months of rent reporting
                        </div>
                      )}
                    </div>
                  ) : null}

                  {isHome ? (
                    <div className="my-3">
                      <div className="flex font-poppins mx-3">
                        <span className="text-white poppins-regular creporting">
                          {homeDue}
                        </span>
                        <span className="text-white due-font-main1  poppins-regular mx-2">
                          billed {isYearly}
                        </span>
                        <span className="text-white font-medium">
                          {/* Calculate and display due amount for today */}
                        </span>
                      </div>

                      <div className="text-white main12  poppins-extralight mx-3">
                        Current rent reporting
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="font-poppins flex flex-col items-center justify-center mt-4 mx-2 sm:mx-auto">
          <div className="flex items-center font-semibold text-gray-300 text-sm p-2 space-x-2">
            <div className="bg-white/30 backdrop-blur-lg rounded-3xl overflow-hidden py-1">
              <span
                className={`rounded-3xl custom-text  px-6 py-2 poppins-light cursor-pointer transition-all duration-500 ${
                  isCardSelected
                    ? "bg-white/30 text-gray-50 transform z-10 relative"
                    : "bg-transparent text-gray-300"
                }`}
                onClick={() => handleSelectionChange(true)}
                data-testid="credit-card"
                aria-label="Select Credit Card"
              >
                Credit card
              </span>
              <span
                className={`rounded-3xl custom-text1 poppins-light px-6 py-2 font-poppins cursor-pointer transition-all duration-500 ${
                  !isCardSelected
                    ? "bg-white/30 text-gray-50 transform z-10 relative"
                    : "bg-transparent text-gray-300"
                }`}
                onClick={() => handleSelectionChange(false)}
                data-testid="bank-account"
                aria-label="Select Bank Account"
              >
                Bank Account
              </span>
            </div>
          </div>
        </div>

        <div className="bg-  font-poppins rounded-lg p-4 w-full max-w-sm">
          <div className="flex items-center justify-center">
            <img src={lock} className="w-[16px] h-[16px] mr-2" alt="Image" />{" "}
            {/* Adjust width and height as needed */}
            <p className="font-poppins custom-text2 poppins-light text-white text-center">
              Payment information not shared.
            </p>
          </div>
          {isCardSelected ? (
            <div className="  mt-3 gap-4">
              <div className="mt-3 gap-4">
                {formErrors.cardNumber && (
                  <p className="text-red-500 text-xs mb-1">
                    {formErrors.cardNumber}
                  </p>
                )}
                <input
                  className={`bg-transparent border-2 my-2 poppins-light placeholder-white text-white w-full mb-2 px-3 py-3 rounded-md ${
                    formData.cardNumber.length && formErrors.cardNumber
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="Card Number"
                  name="cardNumber"
                  type="tel"
                  style={{ borderColor: "white", borderWidth: 1 }}
                  maxLength={19}
                  pattern="[0-9]*"
                  value={formData.cardNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="flex mb-2">
                <div className="flex-1">
                  {/* Error message for expirationDate */}

                  <DatePicker
                    selected={expirationDate}
                    dateFormat="MM/yyyy"
                    minDate={minDate}
                    showMonthYearPicker
                    onChange={(date) => handleDateChange(date, "fieldName")}
                    customInput={
                      <CustomDatePickerInput placeholder="Expiration Date" />
                    }
                    placeholderText="Expiration Date"
                    className={`bg-transparent border-2 my-2 mx-1 poppins-light placeholder-white f text-white w-full mb-2 px-3 border-white py-3 rounded-md ${
                      formErrors.expirationDate && "border-red-500"
                    }`}
                  />

                  {expirationDate == null && (
                    <p className="text-red-500 text-xs mb-1">
                      {formErrors.expirationDate}
                    </p>
                  )}
                </div>
                <div className="flex-1 mx-2">
                  {/* Error message for cvv */}

                  <input
                    className={`bg-transparent border-2 my-2 mx-1 poppins-light placeholder-white f text-white w-full mb-2 px-3 py-3 rounded-md ${
                      formErrors.cvv && "border-red-500"
                    }`}
                    placeholder="CVV"
                    type="password" // Set the type to "password"
                    style={{ borderColor: "white", borderWidth: 1 }}
                    name="cvv"
                    maxLength={4}
                    value={sanitizeInput(formData.cvv)}
                    onChange={handleChange}
                  />

                  {formErrors.cvv && (
                    <p className="text-red-500 text-xs mb-1">
                      {formErrors.cvv}
                    </p>
                  )}
                </div>
              </div>

              {/* <div className="flex mb-2">
                <input
                  className=" bg-transparent  border-2 my-2  placeholder-white  font-poppins  border-purple-400 text-white  w-1/2 mr-2 px-3 py-3 rounded-md"
                  placeholder="Expiration Date"
                  name="expirationDate"
                  value={formData.expirationYear}
                  onChange={handleChange}
              />
                <input
                  type="password" // Change type to password to mask input
                  className="bg-transparent border-2 my-2 font-poppins placeholder-white font-medium text-white w-full mb-2 px-3 py-3 rounded-md"
                  placeholder="CVV"
                  name="cvv"
                  value={maskCVV(formData.cvv)} // Mask the CVV value
                  onChange={handleChange}
                />
              </div> */}

              <input
                className={`bg-transparent border-2 poppins-light my-2  placeholder-white  text-white w-full mb-2 px-3 py-3 rounded-md ${
                  formErrors.cardholderName && "border-red-500" // Apply red border if there's an error
                }`}
                placeholder="Cardholder Name"
                name="cardholderName"
                value={sanitizeInput(formData.cardholderName)}
                onChange={handleChange}
              />
              {formErrors.cardholderName && (
                <p className="text-red-500 text-xs mb-1">
                  {formErrors.cardholderName}
                </p>
              )}
            </div>
          ) : (
            <div className="  gap-4 mt-3">
              {formErrors.accountNumber && (
                <p className="text-red-500 text-xs mb-1">
                  {formErrors.accountNumber}
                </p>
              )}

              <input
                className={`bg-transparent border-2 my-2 poppins-light placeholder-white  text-white w-full mb-2 px-3 py-3 rounded-md ${
                  formErrors.accountNumber && "border-red-500"
                }`}
                pattern="[0-9]*" // Allow only digits
                placeholder="Account Number"
                name="accountNumber"
                type="tel" // Use type="tel" to avoid default number input behavior
                style={{ borderColor: "white", borderWidth: 1 }} // Set border color to white
                maxLength={17}
                value={sanitizeInput(formData.accountNumber)}
                onChange={handleChange}
              />

              {formErrors.routingNumber && (
                <p className="text-red-500 text-xs mb-1">
                  {formErrors.routingNumber}
                </p>
              )}
              <input
                className={`bg-transparent border-2 my-2 poppins-light placeholder-white  text-white w-full mb-2 px-3 py-3 rounded-md ${
                  formErrors.routingNumber && "border-red-500"
                }`}
                placeholder="Routing Number"
                type="tel" // Use type="tel" to avoid default number input behavior
                style={{ borderColor: "white", borderWidth: 1 }} // Set border color to white
                maxLength={9}
                name="routingNumber"
                value={sanitizeInput(formData.routingNumber)}
                onChange={handleChange}
              />
            </div>
          )}
          {/* 
          <p className="text-white font-poppins font-normal text-base leading-6 mt-16 mb-2 text-center">
            Type your name here to sign digitally.
          </p>

          <input
            className="bg-transparent border my-2 placeholder-white font-poppins  border-purple-200 text-white w-full mb-2 px-2 py-3 rounded"
            placeholder="Signature"
          /> */}
        </div>
        {/* </div> */}

        <button
          onClick={handleSubmit}
          className="font-semibold p-4 w-[85%]  continue mx-auto poppins-bold  bg-white text-theme sticky mt-20 bottom-0"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PaymentForm;
