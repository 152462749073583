import React from "react";
import { useSelector } from "react-redux";
import { SessionState } from "../../redux/sessionSlice";
import { useNavigate } from "react-router-dom";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import { Config } from "../../Utils/AppConstants";
import "./CancelSubscriptionScreen.css"; // Import your CSS file for animations
import outer_eclipse from "../../assets/Images/cancel_trial_eclipse.png";
import credit_card from "../../assets/Images/cancel_close.png";
import { makeApiCall } from "../../services/ApiService";
import { API_CALL_TYPE, CANCEL_SUBSCRIPTION } from "../../services/Api";
import { ToastContentProps, toast } from "react-toastify";

const CancelSubscription = () => {
  const navigate = useNavigate();
  const gradientColorStart = useSelector(
    (state: SessionState) => state?.session.filteredFintechData
  );
  const activeSubscription = useSelector(
    (state: SessionState) => state?.session.filteredActiveSubscription
  );

  const gradientColorStart1 =
    gradientColorStart.length > 0
      ? gradientColorStart[0].gradientColorStart
      : "#000000"; // Fallback color
  const btColor =
    gradientColorStart.length > 0
      ? gradientColorStart[0].gradientColorStart
      : "#000000"; // Fallback color
  const fintechIcon =
    gradientColorStart.length > 0 ? gradientColorStart[0].fintechIcon : ""; // Fallback for icon
  const hexToRgb = (hex: string) => {
    // Remove the leading # if it's there
    hex = hex.replace(/^#/, "");

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    // Normalize the RGB values to be between 0 and 1 (feColorMatrix expects normalized values)
    return { r: r / 255, g: g / 255, b: b / 255 };
  };

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  const handleCancelResponse = (response: any) => {
    console.log("CANCELLED RESPONSE:", response);

    if (response.status === 200) {
      // You might want to show a success message or redirect the user
      showCustomToast(response.data, true);
      navigate("/cancelled"); // Redirect to a success page
    } else {
      // Handle any unexpected status codes if necessary
      console.error("Unexpected response status:", response.status);
      showCustomToast(response.data, false);
      navigate("/cancelled"); // Redirect to a success page

      // alert("An unexpected error occurred. Please try again.");
    }
  };
  const handleCanceError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const handleCancelTrial = async () => {
    const billingDetailCode = activeSubscription?.billingDetailCode;

    if (!billingDetailCode) {
      console.error("BillingDetailCode is missing");
      return; // Exit if no billing detail code is available
    }

    const requestData = {
      BillingDetailCode: billingDetailCode,
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.POST_CALL,
        CANCEL_SUBSCRIPTION(),
        handleCancelResponse,
        handleCancelResponse,
        "",
        "",
        JSON.stringify(requestData) // Convert the request data to a JSON string
      );
    } catch (error) {
      console.error("Error canceling subscription:", error);
      // Optionally, handle error (e.g., show an error message)
    }
  };

  return (
    <div className="flex justify-center items-center overflo h-screen">
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style={{
          background: `linear-gradient(${gradientColorStart1} 0%, rgb(113, 198, 1) 7%, rgb(174, 221, 1) 20%, rgb(174, 221, 1) 100%)`,
        }}
      >
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow">
            <img
              alt="back"
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 p-4 flex flex-col ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          {" "}
          <div className="flex flex-col items-center justify-center flex-grow">
            <div className="cancel-trial-title">Cancel Trial</div>
            <div className="relative p-4 rounded-full">
              <img
                src={outer_eclipse} // replace with your outer circle image
                alt="Outer Circle"
                className="h-40 w-40" // Adjust size as needed
              />
              <img
                src={credit_card} // replace with your credit card image
                alt="Credit Card"
                className="absolute inset-0 h-24 w-24 m-auto p-2" // Center the credit card and add padding
              />
            </div>
            <p className="cancel-text-cancel">
              Are you sure you want to cancel?
            </p>{" "}
            <p className="continue-access-text-cancel">
              You will continue to have access until
            </p>
            <p className="continue-access-text">August 27, 2024.</p>
          </div>
          {/* Buttons */}
          <div className="flex justify-between px-6 py-4 space-x-4">
            <button
              className="manage-trial-btn-cancel"
              style={{ backgroundColor: btColor }} // Use dynamic color
            >
              GO BACK
            </button>
            <button
              className="cancel-trial-btn-txt-trial"
              onClick={handleCancelTrial} // Add the click handler
            >
              Cancel Trial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
