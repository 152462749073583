import React, { useState, useEffect } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import pwa_image from "../../assets/Images/pwa_image.png";
import PastRent from "../../components/PastRent";
import "../../assets/css/style.css";
import HomeRent from "../../components/HomeRent";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { SessionData, SessionUpdateRequest } from "../../types/types";
import { Config } from "../../Utils/AppConstants";
import { API_CALL_TYPE, UPDATE_SESSION } from "../../services/Api";
import { makeApiCall } from "../../services/ApiService";
import LoaderComponent from "../../components/LoaderComponent";
import { ToastContentProps, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionData,
  setLoading,
  setError,
  SessionState,
} from "../../redux/sessionSlice";

export default function HomeScreen() {
  const [isMonthly, setIsMonthly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false); // State to control the visibility of the loader
  const [updateSessionLoading, setUpdateSessionLoading] = useState(false);
  const navigate = useNavigate();
  const [clickedHeader, setClickedHeader] = useState("");
  const [showHomeRent, setShowHomeRent] = useState(false);
  const [showPastRent, setShowPastRent] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [pastRentReporting12, setPastRentReporting12] = useState(true);
  const [pastRentReporting24, setPastRentReporting24] = useState(false);
  const [currentRentReporting, setCurrentRentReporting] = useState(true);
  const [onAddHome, setonAddHome] = useState(false);
  const [oncloseHome, setonCloseHome] = useState(false);
  const [onclosePast, setonClosePast] = useState(false);
  const [onAddPast, setonAddPast] = useState(false);
  const [dueAmount, setDueAmount] = useState(0); // Initial monthly due amount
  const [optionselected, setSelectedOption] = useState(12); // Initial monthly due amount
  const location = useLocation();
  const [isChangingData, setisChangingData] = useState(true);

  const [dynamicLink, setDynamicLink] = useState("");
  const passedData = location.state;
  const previousData = location.state;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [fromPayment, setPayment] = useState("");
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  const headerState = useSelector(
    (state: SessionState) => state?.session.clickedHeader
  );

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#FF6347" : "#A2252C", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  useEffect(() => {}, [isMonthly]);

  useEffect(() => {
    setShowLoader(isLoading); // Show loader when isLoading is true
  }, [isLoading]);

  useEffect(() => {
    // Perform additional actions here
  }, [optionselected]);

  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");

  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");

  const handleToggle = () => {
    setIsMonthly((prevIsMonthly) => !prevIsMonthly);
    checkIsMonthly(); // Call checkIsMonthly after toggling isMonthly
  };

  const checkIsMonthly = () => {
    setisChangingData(false);
    let dataToUse; // Declare variable to hold the data to be used

    if (UpdatedLocalData) {
      dataToUse = UpdatedLocalData;
    }

    if (headerState === "Ongoing Rent Reporting") {
      if (isMonthly) {
        setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(98);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(114);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
          if (showHomeRent) {
            setDueAmount(49);
            setCurrentRentReporting(true);
          }
        }
      } else {
        setSubscriptionType("Monthly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(55.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(71.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          if (showHomeRent) {
            setCurrentRentReporting(true);
            setDueAmount(6.95);
          }
        }
      }
      // Add your conditional code here based on the value of clickedHeader
    } else if (headerState === "Past Rent Reporting") {
      if (isMonthly) {
        setSubscriptionType("Yearly"); // Set subscription type to "Yearly"

        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(98);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setDueAmount(114);
              setCurrentRentReporting(true);
            } else {
              setDueAmount(65);
              setCurrentRentReporting(false);
            }
          }
        } else {
          if (showHomeRent) {
            setCurrentRentReporting(true);
            setDueAmount(49);
          } else {
            setCurrentRentReporting(false);
            setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
          }
        }
      } else {
        setSubscriptionType("Monthly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(55.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setDueAmount(71.95);
              setCurrentRentReporting(true);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          if (showHomeRent) {
            setCurrentRentReporting(true);
            setDueAmount(6.95);
          } else {
            setCurrentRentReporting(false);
          }
        }
      }
    } else if (headerState === "Past Rent Reporting") {
      if (isMonthly) {
        setSubscriptionType("Yearly"); // Set subscription type to "Yearly"

        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(98);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setDueAmount(114);
              setCurrentRentReporting(true);
            } else {
              setDueAmount(65);
              setCurrentRentReporting(false);
            }
          }
        } else {
          if (showHomeRent) {
            setCurrentRentReporting(true);
            setDueAmount(49);
          } else {
            setCurrentRentReporting(false);
            setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
          }
        }
      } else {
        setSubscriptionType("Monthly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(55.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setDueAmount(71.95);
              setCurrentRentReporting(true);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          if (showHomeRent) {
            setCurrentRentReporting(true);
            setDueAmount(6.95);
          } else {
            setCurrentRentReporting(false);
          }
        }
      }

      // Add another conditional code block here based on a different value of clickedHeader
    } else if (headerState === "Past & Ongoing Rent Reporting") {
      if (isMonthly) {
        setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(98);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            if (showHomeRent) {
              setCurrentRentReporting(true);
              setDueAmount(114);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          setSubscriptionType("Yearly"); // Set subscription type to "Yearly"
          if (showHomeRent) {
            setDueAmount(49);
            setCurrentRentReporting(true);
          }
        }
      } else {
        setSubscriptionType("Monthly"); // Set subscription type to "Yearly"
        if (showPastRent) {
          if (optionselected == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setShowHomeRent(true);
              setDueAmount(55.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(49);
            }
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);

            if (showHomeRent) {
              setCurrentRentReporting(true);
              setShowHomeRent(true);
              setDueAmount(71.95);
            } else {
              setCurrentRentReporting(false);
              setDueAmount(65);
            }
          }
        } else {
          setCurrentRentReporting(true);
          setShowHomeRent(true);
          setDueAmount(6.95);
        }
      }
    }
  };
  useEffect(() => {
    let dataToUse; // Declare variable to hold the data to be used

    // Check if UpdatedLocalData contains data and use it if available
    if (UpdatedLocalData) {
      dataToUse = UpdatedLocalData;
    } else {
      dataToUse = updatedsessionData;
    }
    if (dataToUse) {
      const sessionPages = dataToUse.data.sessionPages;
      if (sessionPages && sessionPages.length >= 1) {
        let dynamicLink = "";
        sessionPages.forEach((page: { pageName: string; pageData: string }) => {
          if (page && page.pageName === "ASAVaultDynamicLink") {
            const pageData = JSON.parse(page.pageData);
            dynamicLink = pageData.dynamicLink;
            setDynamicLink(dynamicLink);
            // window.location.href = dynamicLink;
          }
        });
      }
    }
  }, [updatedsessionData]);

  useEffect(() => {
    let dataToUse; // Declare variable to hold the data to be used

    // Check if UpdatedLocalData contains data and use it if available
    if (UpdatedLocalData) {
      dataToUse = UpdatedLocalData;
    } else {
      dataToUse = updatedsessionData;
    }

    if (dataToUse && previousData === "fromPayment" && isChangingData) {
      setPayment("fromPayment");
      const sessionPages = dataToUse.data.sessionPages;
      if (sessionPages && sessionPages.length >= 2) {
        const pageData = JSON.parse(sessionPages[2].pageData); // Assuming the second page, index 1
        const subscriptionTypeValue = pageData.SubscriptionType;
        const PastRentReporting12 = pageData.PastRentReporting12;
        const PastRentReporting24 = pageData.PastRentReporting24;
        const CurrentRentReporting = pageData.CurrentRentReporting;

        const clikedHeader = pageData.ClickedHeader;
        setClickedHeader(clikedHeader);
        let amount; // Declare amount variable

        let totalAmount = 0; // Initialize totalAmount variable to zero

        if (
          subscriptionTypeValue &&
          subscriptionTypeValue.toLowerCase() == "monthly"
        ) {
          setIsMonthly(true);
          setPastRentReporting12(false);
          setPastRentReporting24(false);

          if (PastRentReporting12) {
            setPastRentReporting12(true);
            setSelectedOption(12);
            amount = 49;
            setShowPastRent(true);
            totalAmount += amount; // Add amount to totalAmount
          }
          if (PastRentReporting24) {
            setPastRentReporting24(true);
            setSelectedOption(24);
            setShowPastRent(true);
            amount = 65;
            totalAmount += amount; // Add amount to totalAmount
          }
          setCurrentRentReporting(false);
          if (CurrentRentReporting) {
            setCurrentRentReporting(true);
            amount = 6.95;
            setShowHomeRent(true);
            totalAmount += amount; // Add amount to totalAmount
          }

          updateDueAmount(totalAmount); // Update with totalAmount
          setSubscriptionType("Monthly");
        } else {
          setCurrentRentReporting(false);
          setPastRentReporting12(false);
          setPastRentReporting24(false);

          if (PastRentReporting12) {
            setSelectedOption(12);
            setPastRentReporting12(true);
            amount = 49;
            setShowPastRent(true);
            totalAmount += amount; // Add amount to totalAmount
          }
          if (PastRentReporting24) {
            setPastRentReporting24(true);

            setSelectedOption(24);
            setShowPastRent(true);
            amount = 65;
            totalAmount += amount; // Add amount to totalAmount
          }
          if (CurrentRentReporting) {
            amount = 49;
            setCurrentRentReporting(true);
            setShowHomeRent(true);
            totalAmount += amount; // Add amount to totalAmount
          }
          updateDueAmount(totalAmount); // Update with totalAmount
          setSubscriptionType("Yearly");
        }

        // Now you have currentRentValue and pastRentValue calculated based on the retrieved data
      } else {
        console.error("Session data doesn't have enough pages.");
      }
    } else {
      //console.error("updatedsessionData is undefined or null.");
    }
  }, [UpdatedLocalData, updatedsessionData, optionselected, isChangingData]);

  const updateSession = async (updatedSessionData: SessionUpdateRequest) => {
    // alert(subscriptionType);
    setUpdateSessionLoading(true);
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-CRB",
      pageCount: updatedSessionData.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: Config.sessionID,
          pageNumber: "2", // Assuming this is the second page
          pageName: "HomeScreen",
          pageTitle: "Home Screen",
          pageDescription: "Description of Home Screen",
          pageUrl: "/home",
          pageStatus: "Completed",
          pageStatusMessage: "New Home Screen",
          pageData: JSON.stringify({
            SubscriptionType: subscriptionType,
            PastRentReporting12: pastRentReporting12,
            PastRentReporting24: pastRentReporting24,
            CurrentRentReporting: currentRentReporting,
            ClickedHeader: passedData?.clickedHeader,
          }),
        },
      ],
    };

    try {
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );
      await response;

      // Set loading state to false after the API call is completed
      setUpdateSessionLoading(false);

      // return response;
    } catch (error) {
      setUpdateSessionLoading(false);
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    setIsLoading(false);
    setUpdateSessionLoading(false);

    if (response && response?.status == "400") {
      setIsLoading(false);
      // Handle the invalid session error here
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      setIsLoading(false);

      dispatch(setSessionData(response));
      queryClient.setQueryData("updatedSessionData", response);
      navigate("/payment");

      // Dispatch actions, update state, etc.
    }
  };

  const handleUpdateSessionError = (error: any) => {
    setUpdateSessionLoading(false);
    setIsLoading(false);
    console.error("Error updating session:", error);
    // Handle error here
  };

  const handleContinueClick = async () => {
    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        setIsLoading(true);
        await updateSession(dataToUpdate);
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  useEffect(() => {
    if (passedData && passedData.clickedHeader) {
      // Perform any necessary calculations based on the passed data

      if (passedData?.clickedHeader == "Ongoing Rent Reporting") {
        // Calculate due amount based on some logic
        // setDueAmount(/* calculated value */);
        setShowPastRent(false);
        setShowHomeRent(true);
        setClickedHeader("Ongoing Rent Reporting");

        setPastRentReporting12(false);
        setPastRentReporting24(false);
        if (isMonthly) {
          updateDueAmount(6.95);
          setSubscriptionType("Monthly");
        } else {
          setSubscriptionType("Yearly");
          updateDueAmount(49);
        }
      } else if (passedData?.clickedHeader == "Past Rent Reporting") {
        // Calculate due amount based on some other logic
        setClickedHeader("Past Rent Reporting");
        //setDueAmount(/* calculated value */);
        setShowPastRent(true);
        setShowHomeRent(false);
        setCurrentRentReporting(false);
        if (isMonthly) {
          setSubscriptionType("Monthly");
          updateDueAmount(49);
        } else {
          setSubscriptionType("Yearly");
          updateDueAmount(49);
        }

        //  setDueAmount(49)
      } else if (passedData?.clickedHeader == "Past & Ongoing Rent Reporting") {
        // Calculate due amount based on some other logic
        setClickedHeader("Past & Ongoing Rent Reporting");
        setShowPastRent(true);
        setShowHomeRent(true);
        if (isMonthly) {
          setSubscriptionType("Monthly");
          updateDueAmount(55.95);
        } else {
          setSubscriptionType("Yearly");
          updateDueAmount(98);
        }
      }
    }
  }, [passedData]);
  // Define callback function to update dueAmount
  const updateDueAmount = (amount: number) => {
    setDueAmount((prevAmount) => amount);
  };

  const handleHomeRentClick = () => {
    // setShowHomeRent(true);
  };

  const handleCloseClick = () => {
    setonCloseHome(true);
    // setonAddPast(false);

    setShowHomeRent(false);
    setCurrentRentReporting(false);
    if (isMonthly) {
      setDueAmount(dueAmount - 6.95);
    } else {
      setDueAmount(dueAmount - 49);
    }
  };

  const handleHomeRentAdd = () => {
    setonCloseHome(false);
    setonAddHome(true);
    setShowHomeRent(true);
    setCurrentRentReporting(true);

    if (isMonthly) {
      setDueAmount(dueAmount + 6.95);
    } else {
      setDueAmount(dueAmount + 49);
    }
  };

  const handlePastRentAdd = () => {
    setPastRentReporting12(true);
    setPastRentReporting24(false);
    setShowPastRent(true);
    setonAddPast(true);
    if (isMonthly) {
      setDueAmount(dueAmount + 49); // Add 49 when adding PastRent card and monthly
    } else {
      if (optionselected == 12) {
        setDueAmount(dueAmount + 49); // Add 49 when adding PastRent card and monthly
      } else {
        setDueAmount(dueAmount + 65); // Add 49 when adding PastRent card and monthly
      }
    }
  };

  const handlePastRentClose = (amount: any) => {
    // setonAddHome(false);
    // Logic to close the PastRent component

    setPastRentReporting12(false);
    setPastRentReporting24(false);
    setonClosePast(true);
    setDueAmount(dueAmount - amount); // Subtract the amount from the due amount
    setShowPastRent(false);
  };

  const handleOptionSelect = (option: number) => {
    setisChangingData(false);
    setSelectedOption(option);
    if (isMonthly) {
      if (showHomeRent && showPastRent) {
        if (option == 12) {
          setPastRentReporting12(true);
          setPastRentReporting24(false);
          setDueAmount(55.95);
        } else {
          setPastRentReporting12(false);
          setPastRentReporting24(true);
          setDueAmount(71.95);
        }
      } else {
        if (showHomeRent) {
          if (option == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            setDueAmount(55.95);
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            setDueAmount(71.95);
          }
        } else {
          if (option == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            setDueAmount(49);
            //setDueAmount(98);
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            setDueAmount(65);

            // setDueAmount(114);
          }
        }
      }
    } else {
      if (showHomeRent && showPastRent) {
        if (option == 12) {
          setPastRentReporting12(true);
          setPastRentReporting24(false);
          setDueAmount(98);
        } else {
          setPastRentReporting12(false);
          setPastRentReporting24(true);
          setDueAmount(114);
        }
      } else {
        if (showHomeRent) {
          if (option == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            setDueAmount(49);
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            setDueAmount(98);
          }
        } else {
          if (option == 12) {
            setPastRentReporting12(true);
            setPastRentReporting24(false);
            setDueAmount(49);
          } else {
            setPastRentReporting12(false);
            setPastRentReporting24(true);
            setDueAmount(65);
          }
        }
      }
    }
    let dueAmount;
    setSelectedOption(option);
    setShowPastRent(true); // Show PastRent component
  };

  function handleClick() {
    // Check if dynamicLink is defined and not empty
    if (dynamicLink && dynamicLink.trim() !== "") {
      // Redirect to dynamicLink
      window.location.href = dynamicLink;

      setTimeout(() => {
        if (dynamicLink && dynamicLink.trim() !== "") {
          // Redirect to dynamicLink

          navigate("/error", { state: passedData });
        } else {
          // Handle the case when dynamicLink is not valid
          console.error("Dynamic link is not valid or empty.");
          // You can choose to do nothing or provide a fallback behavior here
        }
      }, 2000); // Adjust the delay as needed
    } else {
      // Handle the case when dynamicLink is not valid
      console.error("Dynamic link is not valid or empty.");
      // You can choose to do nothing or provide a fallback behavior here
    }
  }

  // Render loaders based on loading states
  const renderLoader = () => {
    if (isLoading || updateSessionLoading) {
      // Return loader components here
      return <LoaderComponent />;
    }
    return null;
  };

  return (
    <div className="relative bg-theme flex flex-col justify-between overflow-y-scroll py-10 text-gray-100 pm sta mx-auto border space-y-5 h-screen">
      {isLoading && <LoaderComponent />}{" "}
      {/* Render loader based on showLoader state */}
      {/* Render loader based on showLoader state */}
      <div className="flex items-center justify-between">
        <div onClick={handleClick} className="flex items-center p-4 space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8"
            src={arrow_back_ios_new}
            alt="Back"
          />
          <p className="text-sm sm:text-base font-medium asareturn  poppins-semibold text-white">
            return to ASA Vault
          </p>
        </div>
      </div>
      <div className="mx-auto space-y-2">
        <div className="flex flex-col space-y-3 items-center justify-center">
          <p
            className="text-lg summarytxt sm:text-2xl text-gray-100 poppins-semibold cursor-pointer leading-6 mt-3"
            onClick={handleHomeRentClick}
          >
            Your service summary.
          </p>

          <div className="flex flex-col items-center">
            <strong className="custom-font  poppins-semibold mt-5">
              ${dueAmount.toFixed(2)}
            </strong>
            <p className="due-font poppins-regular mt-2">
              due {isMonthly ? "today" : "today"}
            </p>
          </div>

          <div className="flex items-center font-semibold text-gray-300 text-sm space-x-2">
            <div className="bg-white/30 backdrop-blur-lg rounded-3xl overflow-hidden py-1">
              <span
                className={`px-4 py-2 month-toggle poppins-bold text-white cursor-pointer text-xs sm:text-base transition-all duration-500 rounded-full ${
                  isMonthly
                    ? "bg-white/30 text-gray-50"
                    : "bg-transparent text-gray-300"
                }`}
                onClick={handleToggle}
              >
                Monthly
              </span>
              <span
                className={`px-4 py-2  month-toggle cursor-pointer text-xs sm:text-base transition-all duration-500 rounded-full ${
                  !isMonthly
                    ? "bg-white/30 text-gray-50"
                    : "bg-transparent text-gray-300"
                }`}
                onClick={handleToggle}
              >
                Annually
              </span>
            </div>
          </div>

          {isMonthly && (showHomeRent || showPastRent) && showHomeRent && (
            <div className="flex flex-col items-center">
              <strong className="month-span-val poppins-semibold mt-5">
                $6.95
              </strong>
              <p className="month-span poppins-extralight mt-3">due monthly</p>
              <p className="month-span poppins-extralight mt-2">
                starting next month
              </p>
            </div>
          )}
        </div>
      </div>
      {showHomeRent ? (
        <HomeRent isMonthly={isMonthly} onCloseClick={handleCloseClick} />
      ) : (
        <div>
          <p
            onClick={handleHomeRentAdd}
            className="flex items-center justify-center text-sm text-gray-300 cursor-pointer font-poppins"
          >
            <AddCircleOutlineRoundedIcon className="circleaddicon" />
            <span className="poppins-medium ml-2">
              Add current rent reporting
            </span>
          </p>
          <p className=" month-toggle text-sm poppins-medium text-white ml-1 flex items-center justify-center cursor-pointer mt-1">
            Ongoing boost
          </p>
        </div>
      )}
      {showPastRent ? (
        <PastRent
          onCloseClick={() => setShowPastRent(false)}
          onAddToDue={updateDueAmount}
          onSelectOption={handleOptionSelect}
          onPastRentClose={handlePastRentClose}
          selectedOptionProp={optionselected} // Pass the selected option as a prop
        />
      ) : (
        <div>
          <p
            onClick={handlePastRentAdd}
            className="flex items-center justify-center text-sm text-gray-300 cursor-pointer"
          >
            <AddCircleOutlineRoundedIcon className="circleaddicon" />
            <span className="poppins-text">Add past rent reporting</span>
          </p>
          <p className=" month-toggle poppins-medium ml-2  text-white ml-1 flex items-center justify-center text-sm text-gray-300 cursor-pointer mt-1">
            Immediate Boost
          </p>
        </div>
      )}
      {!showHomeRent && !showPastRent && (
        <div>
          <p
            onClick={handleHomeRentAdd}
            className="flex items-center only-font justify-center text-gray-300 cursor-pointer"
            // Increase icon size
          >
            <ArrowUpwardRoundedIcon />
          </p>
          <p className="  creporting poppins-text ml-2 text-lg poppins-semibold text-white ml-1 flex items-center justify-center cursor-pointer mt-1 poppins-700">
            Select a reporting option
          </p>
        </div>
      )}
      <p className="mx-auto text-center text-white leading-6 mt-5">
        <span className="block inf poppins-text mt-8 text-center">
          The information provided will be
        </span>
        <span className="block inf poppins-text text-center">
          shared with Credit Rent Boost to help
        </span>
        <span className="block inf poppins-text text-center">
          improve your credit score.
        </span>
      </p>
      {showHomeRent || showPastRent ? (
        <button
          onClick={handleContinueClick}
          className="poppins-bold  continue sm:w-[60%] md:w-[35%] lg:w-[20%] w-[80%] p-4 mx-auto bg-white text-theme h-[58] text-center"
        >
          Continue
        </button>
      ) : (
        <div className="poppins-bold continue-disable sm:w-[60%] md:w-[35%] lg:w-[20%] w-[80%] p-4 mx-auto bg-white text-theme h-[58] text-center">
          Continue
        </div>
      )}
    </div>
  );
}
