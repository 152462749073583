import React, { useEffect, useState, useCallback } from "react";
import arrow_back_ios_new from "../assets/Images/arrow_back_ios_new.png";
import pwa_image from "../assets/Images/pwa_image.png";
import visa from "../assets/Images/visa.png";
import city_double_cash from "../assets/Images/city_double_cash.png";
import check_everyday from "../assets/Images/check_everyday.png";
import dropdown_arrow from "../assets/Images/arrow_drop_down.png";
import arrow_open from "../assets/Images/arrow_drop_down.png"; // Assuming it's the same as dropdown_arrow
import dropdown_arrow_close from "../assets/Images/arrow_drop_down_down.png";
import more_vert from "../assets/Images/more_vert.png";
import edit_icon from "../assets/Images/edit.png";
import delete_icon from "../assets/Images/delete.png";
import portfolio_logo from "../assets/Images/demo/portfolio_main.svg";

import add_icon from "../assets/Images/add.png";
import { ToastContentProps, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import close_icon from "../assets/Images/close_small_icon.png";
import { useNavigate } from "react-router-dom";
import guac from "../assets/Images/demo/guac_relative_icon.svg";

import { makeApiCall } from "../services/ApiService";
import flash from "../assets/Images/flash_white.png";

import {
  API_CALL_TYPE,
  GET_BILLING_ADDRESSES,
  GET_CONSUMER_ADDRESS,
  POST_CONSUMER_ADDRESS,
  POST_PAYMENT_API,
  POST_PAYMENT_METHOD,
  UPDATE_SESSION,
} from "../services/Api";
import ConfirmDialog from "../components/ConfirmDialog ";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionData,
  setLoading,
  setError,
  SessionState,
  setCOnsumerAdress,
  setSelectedPaymentMethod,
  setSelectedBillingAddress,
  setNewPaymentField,
  setPaymentCVV,
} from "../redux/sessionSlice";
import { data } from "dom7";
import { SessionUpdateRequest } from "../types/types";
import { Config } from "../Utils/AppConstants";
import ConfirmDialogCVV from "../components/ConfirmDialogCVV";
import LoaderComponent from "../components/LoaderComponent";
import { ToyBrick } from "../assets/libs/lucide/cjs/lucide";
interface PaymentMethod {
  arrow_open: any;
  dropdown_arrow_close: any;
  paymentMethodId: number;
  asaPaymentMethodCode: number;
  isDefault: boolean;
  paymentMethodName: string;
  paymentType: string;
  routingNumber: string | null;
  last4DigitsOfAccountNumber: string | null;
  bankName: string;
  cardNumber: string | null;
  accountNumber: string | null;
  cardType: string | null;
  last4DigitsOfCardNumber: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  cardHolderName: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
}

interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}
interface SubscriptionPlanData {
  subscriptionPlanCode: number;
  subscriptionTitle: string;
  description: string;
  oneTimeAmount: number;
  recurringAmount: number;
  billingCycle: string;
  asaFintechCode: number;
  isFree: boolean;
  isActive: boolean;
  freeTrialPeriod: number;
  subscriptionIcon: string | null;
  isRecurring: boolean;
  businessId: number;
  createdByName: string | null;
  createdDate: Date | string;
  updatedByName: string | null;
  updatedDate: Date | string | null;
}

interface SubscriptionPlan {
  pageName: string;
  pageData: SubscriptionPlanData; // Correct type for pageData
}

// Define interfaces for nested objects
interface City {
  cityId: number;
  cityName: string | null;
}

interface State {
  stateId: number;
  stateCode: string | null;
  stateName: string | null;
}

interface Country {
  countryId: number;
  countryName: string | null;
}

interface FintechType {
  fintechTypeId: number;
  fintechType: string;
}

interface FintechCategory {
  fintechCategoryId: number;
  categoryName: string;
}

interface FintechGrantAccessSetup {
  asaFintechCode: number;
  name: string | null;
  logoImage: string | null;
  title: string | null;
  details: string | null;
  helpMessage: string | null;
  accountsNeeded: string | null;
  accountsRequested: string[];
  piiDataOptIn: boolean;
  piiDataMessage: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  createdByName: string | null;
  updatedByName: string | null;
  createdDate: string | null;
  updatedDate: string | null;
}

// Define the main Fintech interface
interface Fintech {
  fintechDetailId: number;
  asaFintechCode: number;
  fintechName: string;
  description: string;
  fintechAPIKey: string | null;
  fintechIcon: string;
  fintechLogo: string;
  fintechType: FintechType;
  address1: string;
  address2: string;
  city: City;
  states: State;
  country: Country;
  postalCode: string;
  state: string;
  mobileAppSupport: boolean;
  browserAppSupport: boolean;
  desktopAppSupport: boolean;
  iosSupport: boolean;
  androidSupport: boolean;
  iosBundleID: string;
  androidBundleId: string;
  fintechStatus: string;
  fintechCategory1: FintechCategory;
  fintechCategory2: FintechCategory;
  fintechCategory3: FintechCategory;
  fintechCategory4: FintechCategory | null;
  fintechCategory5: FintechCategory | null;
  callBackURL: string | null;
  dateCertified: string;
  closeDate: string | null;
  isActive: boolean;
  fiSupport: any[]; // Adjust type if you have specific structure
  productionURL: string | null;
  sandboxURL: string | null;
  documentationURL: string | null;
  productionSubscriptionKey: string;
  sandboxSubscriptionKey: string;
  appPlayStoreUrl: string;
  appStoreUrl: string;
  appWebResponsiveUrl: string;
  appDesktopUrl: string;
  customDomain: string;
  dynamicLinkAPIKey: string;
  bypassAppPreviewPage: boolean;
  fintechRating: number;
  totalRating: number;
  fiApprovalState: boolean;
  fintechGrantAccessSetup: FintechGrantAccessSetup;
  fintechReferralCode: string;
  browserAPPURL: string;
  fintechReferralURL: string;
  transferThresholdAmount: number;
  allowTransfer: boolean;
  supportPhoneNumber: string | null;
  termsAndConditionURL: string | null;
  privacyURL: string | null;
  disclosuresURL: string | null;
  businessId: number;
  sortOrder: number;
  gradientColorStart: string;
  gradientColorEnd: string;
  buttonColor: string;
  isPaymentRequired: boolean;
  pwalink: string;
  accountShareIsRequired: boolean;
  email: string | null;
  phoneNumber: string | null;
  gradientDirection: string;
}

// Define the structure for the API response
interface ApiResponse {
  data: Fintech[];
}

const ListPayments: React.FC = () => {
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [showbillAddress, setShowPBillAddress] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(
    null
  );
  const [cvv, setCVV] = useState("");
  const [cvvEntered, setCVVEntered] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [cvvError, setCVVError] = useState("");
  const [subscriptionPlanData, setSubscriptionPlanData] =
    useState<SubscriptionPlan | null>(null);
  const queryClient = useQueryClient();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [moreVertOpen, setMoreVertOpen] = useState(false);
  const [isMoreVertVisible, setIsMoreVertVisible] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [moreVertOpenbill, setMoreVertOpenbill] = useState(false);
  const [isMoreVertBillVisible, setIsMoreVertBillVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<
    number | null
  >(null);
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses
  const [dynamicLink, setDynamicLink] = useState("");
  const [fintechName, setFintechName] = useState("");
  const [isBillingDropdownOpen, setIsBillingDropdownOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDialogCVV, setShowDialogCVV] = useState(false);
  const [showConfirmDialogPayment, setShowConfirmDialogPayment] =
    useState(false);
  const [deletePaymentIndex, setDeletePaymentIndex] = useState<number | null>(
    null
  );
  const [visibleOptionsIndex, setVisibleOptionsIndex] = useState<number | null>(
    null
  );
  const dispatch = useDispatch();
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [deletePaymentIndexCard, setDeletePaymentIndexCard] = useState<
    number | null
  >(null);
  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const paymentCVVs = useSelector(
    (state: SessionState) => state.session.paymentCVVs
  );
  const cvvdfromStore = useSelector(
    (state: SessionState) => state?.session.newPaymentField
  );

  // const paymentField = useSelector(
  //   (state: SessionState) => state?.session.newPaymentField
  // );
  const getSelectedBillingfromStore = useSelector(
    (state: SessionState) => state?.session.selectedBillingAddress
  );
  const cvvfromStore = useSelector(
    (state: SessionState) => state?.session.newPaymentField
  );
  const getSelectedPaymentfromStore = useSelector(
    (state: SessionState) => state?.session.selectedPaymentMethod
  );

  const handleCVVChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    paymentMethodId: number
  ) => {
    const newCVV = e.target.value;
    // Ensure that CVV is not logged or displayed inappropriately
    setCVV(newCVV);
    dispatch(setNewPaymentField(newCVV)); // Only use newCVV here

    // Update CVV in Redux store
    dispatch(setPaymentCVV({ paymentMethodId, cvv: newCVV }));

    if (newCVV.length === 4) {
      setIsDropdownOpen(false);
    }
  };

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  const maskCVV = (cvv: string) => {
    return cvv.replace(/./g, "*"); // Mask all characters
  };

  useEffect(() => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData?.data?.sessionPages;

      if (!sessionPages || sessionPages.length === 0) {
        // If sessionPages array is empty or not found, fetch PaymentMethodList from API
        // fetchPaymentMethods();
        // fetchBillingAddresses();
        return;
      }
      sessionPages.forEach((page: { pageName: string; pageData: string }) => {
        try {
          if (page.pageName === "SUBSCRIPTION PLAN") {
            const parsedPageData = JSON.parse(
              page?.pageData.replace(/\\/g, "")
            );

            if (parsedPageData) {
              if (parsedPageData?.asaFintechCode !== null) {
                const parsedFicode = parseInt(
                  parsedPageData?.asaFintechCode,
                  10
                );
                if (!isNaN(parsedFicode)) {
                  Config.asaFicode = parsedFicode;
                } else {
                  // Handle case where asaFicode is not a valid number
                }
              }
            }
          }
        } catch (error) {
          console.error(`Error processing page '${page.pageName}':`, error);
        }
      });

      Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
      Config.asaConsumerCode = parseInt(
        UpdatedLocalData?.data?.asaConsumerCode
      );
      const versionCode = UpdatedLocalData?.data?.version;
      Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
      queryClient.setQueryData("sessionID", Config.sessionID);
    }

    if (selectedPaymentIndex !== null) {
      const selectedPaymentMethod = paymentMethods[selectedPaymentIndex];
      const storedCVV =
        paymentCVVs[selectedPaymentMethod.paymentMethodId] || "";
      setCVV(storedCVV);
    }
  }, [selectedPaymentIndex, paymentMethods, paymentCVVs]);

  useEffect(() => {
    FetchConsumer();
    if (!UpdatedLocalData) {
      // If UpdatedLocalData is empty or null, fetch PaymentMethodList and BillingAddressList from API
      // fetchPaymentMethods();
      // fetchBillingAddresses();
      return; // Exit early if there's no UpdatedLocalData
    }

    // Extract sessionPages from UpdatedLocalData
    const sessionPages = UpdatedLocalData.data.sessionPages;

    if (!sessionPages || sessionPages.length === 0) {
      // If sessionPages array is empty or not found, fetch PaymentMethodList from API
      // fetchPaymentMethods();
      // fetchBillingAddresses();
      return;
    }
    let foundPaymentMethods = false;
    let foundBillAddress = false;
    let selectedPaymentMethod = false;

    sessionPages.forEach((page: { pageName: string; pageData: string }) => {
      try {
        if (page.pageName === "GOALS PLANS") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          if (parsedPageData) {
            const subplan: SubscriptionPlan = {
              pageName: page.pageName,
              pageData: parsedPageData,
            };
            setSubscriptionPlanData(subplan);
            const dynamicLink = parsedPageData?.billingCycle;
            setDynamicLink(dynamicLink);
          }
        } else if (page.pageName === "GOALS PLANS") {
          const parsedPageData: SubscriptionPlanData = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          if (parsedPageData) {
            const subplan: SubscriptionPlan = {
              pageName: page.pageName,
              pageData: parsedPageData,
            };
            setSubscriptionPlanData(subplan);
            const dynamicLink = parsedPageData?.billingCycle;
            setDynamicLink(dynamicLink);
          }
        } else if (page.pageName === "FintechName") {
          const parsedPageData: Fintech[] = JSON.parse(
            page.pageData.replace(/\\/g, "")
          );
          if (parsedPageData) {
            // const parsedPageData: Fintech[] = [
            //   {
            //     fintechDetailId: 104,
            //     asaFintechCode: 1054208782,
            //     fintechName: "OneGoal",
            //     // other fields...
            //   },
            //   // more Fintech objects...
            // ];

            // Access the fintechName from the first element in the array
            if (parsedPageData.length > 0) {
              const fintechName = parsedPageData[0].fintechName;

              setFintechName(parsedPageData[0].fintechName);
              console.log(fintechName); // Output: OneGoal
            } else {
              console.log("No fintech data available");
            }

            // const subplan: Fintech = {
            //   pageName: page.pageName,
            //   pageData: parsedPageData,
            // };
            // setSubscriptionPlanData(subplan);
            // const fintechName = parsedPageData[0]?.fintechName;
            setFintechName(parsedPageData[0]?.fintechName);
          }
        } else if (page.pageName === "PaymentMethodList") {
          foundPaymentMethods = true;
          const paymentMethodsData = JSON.parse(page.pageData);
          if (
            Array.isArray(paymentMethodsData) &&
            paymentMethodsData.length > 0
          ) {
            //

            setPaymentMethods(paymentMethodsData);

            if (paymentMethodsData.length === 0) {
              dispatch(setSelectedPaymentMethod(null));
              // fetchPaymentMethods();
            } else {
              // setPaymentMethods(paymentMethodsData);

              if (
                getSelectedPaymentfromStore &&
                Object.keys(getSelectedPaymentfromStore).length > 0
              ) {
                const selectedPaymentMethod = getSelectedPaymentfromStore;
                const cvv =
                  paymentCVVs[selectedPaymentMethod.paymentMethodId] || "";
                setCVV(cvv);
                dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

                setIsDropdownOpen(false);
              } else {
                setIsDropdownOpen(true);
                dispatch(setSelectedPaymentMethod(null));
              }
            }
          }
        } else if (page.pageName === "SelectedPaymentMethod") {
          selectedPaymentMethod = true;

          const selectedPaymentMenthodData = JSON.parse(page.pageData);

          if (
            Array.isArray(selectedPaymentMenthodData) &&
            selectedPaymentMenthodData.length > 0
          ) {
            //

            setSelectedPaymentMethod(selectedPaymentMenthodData);

            dispatch(setSelectedPaymentMethod(selectedPaymentMenthodData));
            if (selectedPaymentMenthodData.length === 0) {
              dispatch(setSelectedPaymentMethod(null));
              // fetchPaymentMethods();
            } else {
              // setPaymentMethods(paymentMethodsData);

              if (
                getSelectedPaymentfromStore &&
                Object.keys(getSelectedPaymentfromStore).length > 0
              ) {
                setIsDropdownOpen(false);
              } else {
                setIsDropdownOpen(true);
                dispatch(setSelectedPaymentMethod(null));
              }
            }
          }
        } else if (page.pageName === "BillAddressList") {
          foundBillAddress = true;
          const BillAddressData = JSON.parse(page.pageData);

          if (Array.isArray(BillAddressData) && BillAddressData.length > 0) {
            setBillingAddresses(BillAddressData);

            if (
              getSelectedBillingfromStore &&
              Object.keys(getSelectedBillingfromStore).length > 0
            ) {
              setIsBillingDropdownOpen(false);
            } else {
              setIsBillingDropdownOpen(true);
            }
          } else {
            dispatch(setSelectedBillingAddress(null));
            setIsBillingDropdownOpen(true);
          }
        }
      } catch (error) {
        console.error(`Error processing page '${page.pageName}':`, error);
      }
    });

    // If BillAddressList data is not found, fetch it from the API
    if (!foundBillAddress) {
      // fetchBillingAddresses();
      dispatch(setSelectedPaymentMethod(null));
    }

    // If PaymentMethodList data is not found, fetch it from the API
    if (!foundPaymentMethods) {
      setIsDropdownOpen(true);
      // fetchPaymentMethods();
    }

    if (!selectedPaymentMethod) {
      setIsDropdownOpen(true);
      dispatch(setSelectedPaymentMethod(null));
    }
  }, []);

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const FetchConsumer = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_CONSUMER_ADDRESS(),
        handleConsumerAddress,
        handleCOnsumerError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleDeletePaymentMethod = async (index: number) => {
    setDeletePaymentIndex(index);
    setShowConfirmDialog(true);
  };

  const handleDeletePaymentMethodCards = async (index: number) => {
    setShowConfirmDialogPayment(true);
    setDeletePaymentIndexCard(index);
  };
  const toggleBillingDropdown = () => {
    setIsBillingDropdownOpen(!isBillingDropdownOpen);
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleReviewAndPayClick = () => {
    // Handle click action, such as navigating to another screen or performing some other action
    const title =
      subscriptionPlanData && subscriptionPlanData.pageData.subscriptionTitle;

    if (title === "Gold Subscription") {
      navigate("/guac");
    } else if (title === "Gold" || title === "Platinum") {
      navigate("/PortfolioPilot");
    } else {
      navigate("/onegoal");
    }
  };

  const handleConsumerResponse = async (response: any) => {
    // Handle success response
    setIsLoading(false);
    showCustomToast("Consumer Address deleted successfully", false);
  };

  const handleConsumerError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, true);
  };

  const updateSessionDeletion = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);

    try {
      let currentSessionData = UpdatedLocalData || {};
      let sessionPages = [...(currentSessionData?.data?.sessionPages || [])];

      // Check if the "BillAddressList" page exists
      const existingPageIndex = sessionPages.findIndex(
        (page: any) => page.pageName === "BillAddressList"
      );

      if (existingPageIndex !== -1) {
        let existingPageData = sessionPages[existingPageIndex].pageData;
        let pageDataArray = [];

        try {
          pageDataArray = JSON.parse(existingPageData);
        } catch (error) {}

        if (Array.isArray(pageDataArray)) {
          // Remove the specific record if found
          pageDataArray = pageDataArray.filter(
            (item: any) =>
              item.consumerAddressId !== session_if_any.consumerAddressId
          );

          // Update the session page with the modified data
          sessionPages[existingPageIndex] = {
            ...sessionPages[existingPageIndex],
            pageData: JSON.stringify(pageDataArray), // Serialize the updated data
          };
        } else {
          // If pageData was not an array, reset to an empty array
          sessionPages[existingPageIndex] = {
            ...sessionPages[existingPageIndex],
            pageData: JSON.stringify([]), // Set to empty array as there were no valid records
          };
        }
      } else {
        // If the page doesn't exist, there’s nothing to delete
        return; // Exit early since there’s nothing to delete
      }

      const sessionUpdateRequest: SessionUpdateRequest = {
        asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,
        asaConsumerCode: UpdatedLocalData?.data?.asaConsumerCode,
        version: Config.X_ASA_version.toString(),
        sessionName: "ASAVAULT-ONEGOAL",
        pageCount: "3",
        status: "Incomplete",
        statusMessage: "INCOMPLETE",
        sessionPages: sessionPages,
      };

      try {
        const response = await makeApiCall(
          API_CALL_TYPE.PUT_CALL,
          UPDATE_SESSION(),
          handleUpdateSessionResponse,
          handleUpdateSessionError,
          "",
          "",
          sessionUpdateRequest
        );

        return response;
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error updating session:"));
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletePaymentIndex !== null) {
      try {
        const billingAddressToDelete = billingAddresses[deletePaymentIndex];
        setIsLoading(true);

        const requestData = [
          {
            consumerAddressId: billingAddressToDelete.consumerAddressId,
            address1: billingAddressToDelete.address1,
            city: billingAddressToDelete.city,
            state: billingAddressToDelete.state,
            zipCode: billingAddressToDelete.zipCode,
            firstName: billingAddressToDelete.firstName,
            lastName: billingAddressToDelete.lastName,
            country: billingAddressToDelete.country,
            isCurrent: false,
            isActive: false,
            moveoutDate: null,
            isBilling: true,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];

        const response = await makeApiCall(
          API_CALL_TYPE.POST_CALL,
          POST_CONSUMER_ADDRESS(),
          handleConsumerResponse,
          handleConsumerError,
          "",
          "",
          requestData
        );

        try {
          let dataToUpdate; // Declare variable to hold the data to be updated

          // Check if UpdatedLocalData contains data and use it if available
          if (UpdatedLocalData) {
            dataToUpdate = UpdatedLocalData;
          } else {
            dataToUpdate = updatedsessionData;
          }

          if (dataToUpdate) {
            // Set loading state to true before making the API call
            setIsLoading(true);
            //    await updateSession(dataToUpdate);

            await updateSessionDeletion(dataToUpdate, billingAddressToDelete);

            // Navigate to payment screen after successful API call
            // navigate("/payment");
          } else {
            console.error("No session data available to update.");
          }
        } catch (error) {
          setIsLoading(false);

          // Handle error here
        } finally {
          // Set loading state to false after API call is completed (whether success or error)
        }

        //  await updateSessionDeletion(billingAddressToDelete);

        const updatedBillingAddresses = [...billingAddresses];
        if (
          getSelectedBillingfromStore &&
          getSelectedBillingfromStore.consumerAddressId ===
            billingAddressToDelete.consumerAddressId
        ) {
          dispatch(setSelectedBillingAddress(null));
        }

        updatedBillingAddresses.splice(deletePaymentIndex, 1);
        setBillingAddresses(updatedBillingAddresses);
        setDeletePaymentIndex(null);
        setShowConfirmDialog(false);
      } catch (error) {
        console.error("Error deleting billing address:", error);
      }
    }
  };

  const handleDeletePaymentResponse = async (response: any) => {
    // Handle success response
    setIsLoading(false);
    showCustomToast(response.message, false);
    // navigate("/ListPayments");
  };

  const handlePaymentMethodError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, true);
  };

  const handleConfirmDeletePaymet = async () => {
    if (deletePaymentIndexCard !== null) {
      try {
        try {
          // Prepare the data for deletion
          const paymentMethodToDelete = paymentMethods[deletePaymentIndexCard];

          // Headers including asaPaymentMethodCode

          // Make API call to delete payment method with headers
          makeApiCall(
            API_CALL_TYPE.DELETE_CALL, // Using DELETE method
            POST_PAYMENT_METHOD(), // Endpoint URL for DELETE
            handleDeletePaymentResponse, // Success callback
            handlePaymentMethodError, // Error callback
            null, // formData (not used in this case)
            "", // token (not used in this case)
            {
              asaPaymentMethodCode: paymentMethodToDelete?.asaPaymentMethodCode,
            } // Body for DELETE
          );

          const updatedBillingAddresses = [...paymentMethods];
          updatedBillingAddresses.splice(deletePaymentIndexCard, 1);
          setPaymentMethods(updatedBillingAddresses);
          setDeletePaymentIndexCard(null);
          setShowConfirmDialogPayment(false);

          // If successful, update local state
          // const updatedPaymentMethods = paymentMethods.filter(
          //   (_, index) => index !== deletePaymentIndexCard
          // );
          // setPaymentMethods(updatedPaymentMethods);
          // setDeletePaymentIndexCard(null);
          // setShowConfirmDialogPayment(false);
        } catch (error) {
          // Handle error scenarios
        }
        // Perform deletion logic here, e.g., calling API to delete the billing address
      } catch (error) {
        console.error("Error deleting payment Address address:", error);
        // Handle error scenarios
      }
    }
  };

  const handleEditPaymentMethod = (index: number) => {
    const paymentMethodsIn = paymentMethods[index];

    navigate("/PaymentOneGoal", { state: { payment: paymentMethodsIn } });
  };

  const handleEditBillingAddress = (index: number) => {
    const selectedAddress = billingAddresses[index];

    navigate("/Address", { state: { address: selectedAddress } });
  };

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);
    if (formattedPaymentMethods.length == 0) {
      setIsDropdownOpen(true);
    }
    //  setIsDropdownOpen(!isDropdownOpen);

    if (
      getSelectedPaymentfromStore &&
      Object.keys(getSelectedPaymentfromStore).length > 0
    ) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
      dispatch(setSelectedPaymentMethod(null));
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call

        await updateSession(
          dataToUpdate,
          formattedPaymentMethods,
          "3",
          "PaymentMethodList",
          "PaymentMethodList"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
      }
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
    setShowPaymentMethods(true); // Show payment methods after data is fetched
    setIsLoading(false); // Stop loading state
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    if (formattedBillingAddresses.length) {
      setBillingAddresses(formattedBillingAddresses);
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        setIsLoading(true);

        await updateSession(
          dataToUpdate,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList"
        );

        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const handleConsumerAddress = (data: any) => {
    dispatch(setCOnsumerAdress(data));
    setIsLoading(false); // Stop loading state on error
  };

  const handleCOnsumerError = (error: any) => {
    console.error("API Error: ", error);
  };

  const togglePaymentMethods = () => {
    //navigate("/AddMethods");

    setIsDropdownOpen(!isDropdownOpen);
    setShowPaymentMethods(!showPaymentMethods);
  };

  const onAddPaymentMethod = () => {
    navigate("/AddMethods");

    // setIsDropdownOpen(!isDropdownOpen);
    // setShowPaymentMethods(!showPaymentMethods);
  };

  const toggleBillingAddress = () => {
    //navigate("/AddMethods");

    setIsBillingDropdownOpen(!isBillingDropdownOpen);
    setShowPBillAddress(!showbillAddress);
  };

  const addBilling = () => {
    navigate("/Address");

    // setIsDropdownOpen(!isDropdownOpen);
    // setShowPaymentMethods(!showPaymentMethods);
  };

  const getImageSource = (type: string) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      // navigate("/ListPayments");
      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const updateSession = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,

    pageTitle: string
  ) => {
    dispatch(setLoading(true));
    //setIsLoading(true);

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,
      asaConsumerCode: UpdatedLocalData?.data?.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: session_if_any.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: "INCOMPLETE",
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,

          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,

          pageDescription: pageTitle,
          pageUrl: "/PaymentList",
          pageStatus: "Completed",
          pageStatusMessage: "PaymentList",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };

  const handlePaymentSelection = async (index: number, type: string) => {
    setSelectedPaymentType(type);
    setSelectedPaymentIndex(index);
    setCVVEntered(false); // Reset CVV entered state

    if (type === "CC") {
    } else {
      setIsDropdownOpen(false);
    }

    try {
      const selectedPaymentMethod = paymentMethods[index];
      const cvv = paymentCVVs[selectedPaymentMethod.paymentMethodId] || "";
      setCVV(cvv);
      dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

      // const selectedPaymentMethod = paymentMethods[index];
      // dispatch(setSelectedPaymentMethod(selectedPaymentMethod));

      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        setIsLoading(true);

        await updateSession(
          dataToUpdate,
          selectedPaymentMethod,
          "5",
          "SelectedPaymentMethod",
          "SelectedPaymentMethod"
        );
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      console.error(
        "Error updating session with new billing address and payment method:",
        error
      );
    }
  };

  const handleCVVBlur = () => {
    if (cvv.length === 3) {
      setCVVEntered(true);
      // Proceed with your next steps if needed
    }
  };

  const handleCVVClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleBackClick = () => {
    // if (
    //   subscriptionPlanData &&
    //   subscriptionPlanData.pageData.subscriptionTitle === "Gold Subscription"
    // ) {
    //   // Navigate to the specified path
    //   navigate("/guac");
    // } else {
    //   navigate("/onegoal");
    // }

    const title =
      subscriptionPlanData && subscriptionPlanData.pageData.subscriptionTitle;

    if (title === "Gold Subscription") {
      navigate("/guac");
    } else {
      navigate("/onegoal");
    }
  };

  const handleAddressClick = (index: number) => {};

  const handleAddressSelection = async (
    index: number,
    selectedPaymentIndex: number
  ) => {
    if (selectedAddressIndex === index) {
      // If the same item is clicked again, toggle the options off
      setShowOptions(true);
    } else {
      // Show options for the clicked item
      setSelectedAddressIndex(index);
    }
    setSelectedAddressIndex(index);
    setIsBillingDropdownOpen(!isBillingDropdownOpen);

    try {
      const selectedBillingAddress = billingAddresses[index];
      dispatch(setSelectedBillingAddress(selectedBillingAddress));

      const selectedPaymentMethod = paymentMethods[selectedPaymentIndex]; // Assuming you have selectedPaymentIndex available

      // Prepare the session data to be updated
      const updatedSessionData = {
        billingDetailRequestModel: {
          ...UpdatedLocalData.billingDetailRequestModel,
          firstName: selectedBillingAddress.firstName,
          lastName: selectedBillingAddress.lastName,
          address: selectedBillingAddress.address1,
          city: selectedBillingAddress.city,
          state: selectedBillingAddress.state,
          postalCode: selectedBillingAddress.zipCode,
          country: selectedBillingAddress.country,
          // Include payment method details
          paymentMethodId: getSelectedPaymentfromStore.paymentMethodId,
          asaPaymentMethodCode:
            getSelectedPaymentfromStore.asaPaymentMethodCode,
          paymentType: getSelectedPaymentfromStore.paymentType,
          routingNumber: getSelectedPaymentfromStore.routingNumber,
          last4DigitsOfAccountNumber:
            getSelectedPaymentfromStore.last4DigitsOfAccountNumber,
          bankName: getSelectedPaymentfromStore.bankName,
          cardType: getSelectedPaymentfromStore.cardType,
          cardNumber: getSelectedPaymentfromStore.cardNumber,
          accountNumber:
            getSelectedPaymentfromStore.paymentType === "CC"
              ? null
              : getSelectedPaymentfromStore?.accountNumber,
          last4DigitsOfCardNumber:
            getSelectedPaymentfromStore.last4DigitsOfCardNumber,
          cardHolderName: getSelectedPaymentfromStore?.cardHolderName,
          expirationYear: getSelectedPaymentfromStore?.expirationYear,
          expirationMonth: getSelectedPaymentfromStore?.expirationMonth,
        },
      };

      // Call updateSession function to update the session with new billing address and payment method
      //    await updateSession(updatedSessionData);

      try {
        let dataToUpdate; // Declare variable to hold the data to be updated

        // Check if UpdatedLocalData contains data and use it if available
        if (UpdatedLocalData) {
          dataToUpdate = UpdatedLocalData;
        } else {
          dataToUpdate = updatedsessionData;
        }

        if (dataToUpdate) {
          // Set loading state to true before making the API call

          await updateSession(
            dataToUpdate,
            updatedSessionData,
            "4",
            "ListPaymentBilling",
            "PaymentBillingInfo"
          );
          // Navigate to payment screen after successful API call
          // navigate("/payment");
        } else {
          console.error("No session data available to update.");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating session:", error);
        // Handle error here
      } finally {
        // Set loading state to false after API call is completed (whether success or error)
      }

      // Optionally, navigate to the next screen or handle success
      // navigate("/NextScreen");
    } catch (error) {
      console.error(
        "Error updating session with new billing address and payment method:",
        error
      );
      // Handle error scenarios
    }
    //   navigate("/Address"); // Navigate to the next screen when an address is selected
  };

  const updateSessionPayment = async (
    updatedSessionData: SessionUpdateRequest,
    paymentRequestResponse: any
  ) => {
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,
      asaConsumerCode: UpdatedLocalData?.data?.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: updatedSessionData.pageCount,
      status: paymentRequestResponse?.status == "200" ? "Completed" : "Error", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,

          pageNumber: "3", // Assuming this is the second page
          pageName: "PaymentScreen",
          pageTitle: "PaymentScreen",
          pageDescription: "Description of PaymentScreen",
          pageUrl: "/payment",
          pageStatus:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageStatusMessage:
            paymentRequestResponse?.status == "200" ? "Completed" : "Error",
          pageData: JSON.stringify({
            PaymentData: paymentRequestResponse,
          }),
        },
      ],
    };

    try {
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );

      return response;
    } catch (error) {
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  const handlePostPaymentResponse = async (response: any) => {
    setIsLoading(false);
    if (response?.status === 400 || response?.status === 500) {
      // Show a toast message indicating the appropriate message
      showCustomToast(response?.message, true);
      setIsLoading(false);
      return; // Exit the function early
    }

    if (response?.status === 422) {
      // Show a toast message indicating the appropriate message
      showCustomToast(response?.data?.message, true);
      setIsLoading(false);
      return; // Exit the function early
    }

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      }
      // else {
      //   dataToUpdate = updatedsessionData;
      // }

      if (dataToUpdate) {
        await updateSessionPayment(dataToUpdate, response);
        setIsLoading(false);
        // showCustomToast(response?.message, false);

        if (response?.data?.message === "Payment Received Successfully.") {
          showCustomToast(response?.data?.message, false);

          navigate("/goalAdded", { state: response?.data?.message });

          return; // Exit the function early
        } else if (
          response?.data?.message ===
          "The subscription you selected is already active."
        ) {
          showCustomToast(response?.data?.message, true);

          navigate("/ListPay", { state: response?.data?.message });
        }
      }
    } catch (error) {
      setIsLoading(false);
      showCustomToast("Error updating session:", true);
      // Handle error here
    }
  };

  const handleConfirmCVV = (enteredCVV: string) => {
    setCVV(enteredCVV);
    setShowDialogCVV(false);
    handlePayment(
      getSelectedBillingfromStore,
      getSelectedPaymentfromStore,
      enteredCVV
    );
  };

  const handlePostPaymentError = (error: any) => {
    setIsLoading(false);
    if (error?.message === "Request failed with status code 400") {
      showCustomToast("CVV should contain only 3 or 4 digits", true);
    }

    // Handle error here
  };

  const handleapp = async (billingfromstore: any, paymentfromstore: any) => {
    if (paymentfromstore === null) {
      return; // or handle the error appropriately
    }

    // Check CVV only if payment type is 'cc'
    // if (paymentfromstore?.paymentType === "CC") {
    //   setShowDialogCVV(true);
    //   return;
    // } else {
    handlePayment(getSelectedBillingfromStore, getSelectedPaymentfromStore, "");
    // }
  };

  const handlePayment = async (
    billingfromStore: any,
    paymentfromStore: any,
    enteredCVV?: string
  ) => {
    setIsLoading(true);

    // Check CVV only if payment type is 'cc'
    if (
      paymentfromStore?.paymentType === "cc" &&
      (!/^\d+$/.test(cvv) || !(cvv.length === 3 || cvv.length === 4))
    ) {
      setCVVError("Enter CVV");

      setIsLoading(false);
      showCustomToast(" Error in CVV", true);
      return;
    }
    // Get the CVV corresponding to the selected payment method
    const selectedPaymentMethodId = paymentfromStore?.paymentMethodId;
    const cvvToUse = enteredCVV || paymentCVVs[selectedPaymentMethodId] || cvv; // const selectedBillingAddress = billingAddresses[index];

    // Proceed with your logic here
    const requestBody = {
      products: [""],
      billingDetailRequestModel: {
        billingAmount: subscriptionPlanData?.pageData.oneTimeAmount,
        comment: "Subscription Plan",
        isRecurring: subscriptionPlanData?.pageData.isRecurring,
        RecurringAmount: subscriptionPlanData?.pageData.recurringAmount,
        isSubscription: true,
        consumerSignature: null,
        autoPay: true,
        recurringStartDate: null,
        billingEmail: null,
        paymentMethodId: paymentfromStore?.paymentMethodId,
        paymentMethodCode: paymentfromStore?.asaPaymentMethodCode,
        BillingCycle: subscriptionPlanData?.pageData?.billingCycle,
        isAcceptTerms: true,
        paymentType: null,
        bankAccountNumber: null,
        bankRoutingNumber: null,
        bankName: null,
        cardType: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        firstName: null,
        middleName: null,
        lastName: null,
        CardHolderName:
          paymentfromStore?.paymentType == "ACH"
            ? null
            : paymentfromStore?.cardHolderName,
        CVV: paymentfromStore?.paymentType == "ACH" ? null : cvvToUse,
        subscriptionPlanCode:
          subscriptionPlanData?.pageData.subscriptionPlanCode,
        address: billingfromStore?.address1,
        address2: billingfromStore?.address2,
        city: billingfromStore?.city,
        state: billingfromStore?.state,
        country: billingfromStore?.country,
        postalCode: billingfromStore?.zipCode,
      },
    };

    const response = await makeApiCall(
      API_CALL_TYPE.POST_CALL,
      POST_PAYMENT_API(),
      handlePostPaymentResponse,
      handlePostPaymentError,
      "", // Placeholder for formData argument
      "", // Placeholder for token argument
      requestBody
    );
  };
  const handleMoreOptionsClick = (index: number) => {
    setVisibleOptionsIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCloseOptions = () => {
    setVisibleOptionsIndex(null);
  };

  return (
    <div className="relative gradient-bg-listpayments bg-gradient-to-b from-[#705AE2] to-[#490C95] flex flex-col justify-between overflow-y-scroll py-10 text-gray-100 mx-auto space-y-5 h-screen">
      {" "}
      {isLoading && <LoaderComponent />}{" "}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick} // Updated onClick handler for the back button
          />
          <p className="custom-style-bugs">Checkout with ASA Pay®</p>
        </div>
      </div>
      <div className="absolute top-24 left-0 card-lay right-0 bottom-0 rounded-t-3xl p-4 z-10 overflow-y-auto more-rounded">
        <div className="flex items-center justify-between mt-8 mx-2">
          <p className="payment-heading-list font-poppins font-semibold">
            Select or add a payment method.
          </p>
          <div className="image-container">
            <img
              className="inner-image"
              src={dropdown_arrow}
              alt="Inner Dropdown Arrow"
            />
            <img
              className="dropdown-img cursor-pointer"
              src={isDropdownOpen ? arrow_open : dropdown_arrow_close}
              alt="Dropdown"
              onClick={togglePaymentMethods}
            />
          </div>
        </div>

        {!isDropdownOpen && getSelectedPaymentfromStore && (
          <div className="space-y-2 mt-2">
            <div className="flex items-center justify-between p-2 rounded-lg">
              <div className="flex items-center space-x-2">
                <div className="bg-white rounded p-1">
                  <img
                    src={getImageSource(
                      getSelectedPaymentfromStore.paymentType
                    )}
                    alt={
                      getSelectedPaymentfromStore.paymentType ||
                      "Payment Method"
                    }
                    className="w-8 h-5 rounded"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <span className="method-name mt-name0 truncate text-[#4E4E4E]">
                  {getSelectedPaymentfromStore.paymentMethodName}
                </span>
                <div className="font-poppins text-[#4E4E4E] font-semibold text-base leading-24">
                  ••••{" "}
                  {getSelectedPaymentfromStore.paymentType === "CC"
                    ? getSelectedPaymentfromStore?.last4DigitsOfCardNumber
                    : getSelectedPaymentfromStore?.last4DigitsOfAccountNumber}
                </div>
              </div>
            </div>
          </div>
        )}

        {isDropdownOpen && (
          <div className="space-y-2 X-2 mt-2 max-h-40 overflow-y-auto">
            {isLoading ? (
              <div className="space-y-2 mt-5">
                {[...Array(3)].map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 rounded-lg cursor-pointer bg-gray-200"
                  >
                    {/* Skeleton loaders */}
                  </div>
                ))}
              </div>
            ) : (
              <div className="space-y-2 mt-2 max-h-40 overflow-y-auto">
                {paymentMethods.map((method, index) => {
                  const isSelected =
                    getSelectedPaymentfromStore?.paymentMethodId ===
                    method?.paymentMethodId;
                  const hasStoredCVV =
                    paymentCVVs[method.paymentMethodId] !== undefined;

                  return (
                    <div key={index}>
                      <div
                        className={`flex items-center justify-between p-2 rounded-lg cursor-pointer mb-3`}
                        style={{
                          backgroundColor:
                            selectedPaymentIndex === index || isSelected
                              ? "#D9D9D9"
                              : "transparent",
                          boxShadow:
                            selectedPaymentIndex === index || isSelected
                              ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
                              : "none",
                        }}
                        onClick={() =>
                          handlePaymentSelection(index, method.paymentType)
                        }
                      >
                        <div className="flex items-center space-x-2">
                          <div
                            className="form-radio-outer"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              border: "2px solid #ffffff",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffffff",
                              justifyContent: "center",
                              transition: "width 0.2s, height 0.2s",
                            }}
                          >
                            <div
                              style={{
                                width:
                                  selectedPaymentIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                height:
                                  selectedPaymentIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                borderRadius: "50%",
                                backgroundColor: "#6B46C1",
                                transition: "width 0.2s, height 0.2s",
                              }}
                            />
                          </div>

                          <div className="bg-white rounded p-1">
                            <img
                              src={getImageSource(method.paymentType)}
                              alt={method.paymentType}
                              className="w-8 h-5 rounded"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <span className="method-name mt-name0 truncate text-[#4E4E4E]">
                            {method?.paymentMethodName}
                          </span>

                          {(isDropdownOpen && selectedPaymentIndex === index) ||
                          (method.paymentType === "CC" && isSelected) ? (
                            method.paymentType === "CC" ? (
                              <input
                                style={{ borderColor: "#FFFFFF" }}
                                type="password"
                                placeholder="CVV"
                                value={
                                  paymentCVVs[method.paymentMethodId] || ""
                                }
                                maxLength={4}
                                onChange={(e) =>
                                  handleCVVChange(e, method.paymentMethodId)
                                }
                                onBlur={handleCVVBlur}
                                onClick={handleCVVClick}
                                inputMode="numeric"
                                className="w-20 h-8 p-2 bg-white border border-white rounded font-poppins text-[#4E4E4E] text-base leading-6 outline-none focus:border-white text-center box-border"
                              />
                            ) : (
                              <div className="font-poppins text-[#4E4E4E] font-semibold text-base leading-24">
                                ••••{" "}
                                {method.paymentType === "CC"
                                  ? method?.last4DigitsOfCardNumber
                                  : method?.last4DigitsOfAccountNumber}
                              </div>
                            )
                          ) : (
                            <div className="font-poppins text-[#4E4E4E] font-semibold text-base leading-24">
                              ••••{" "}
                              {method.paymentType === "CC"
                                ? method?.last4DigitsOfCardNumber
                                : method?.last4DigitsOfAccountNumber}
                            </div>
                          )}
                        </div>

                        {selectedPaymentIndex === index && (
                          <>
                            {moreVertOpen ? (
                              <div className="flex items-center space-x-2 ml-auto">
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => handleEditPaymentMethod(index)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={edit_icon}
                                    alt="Edit"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() =>
                                    handleDeletePaymentMethodCards(index)
                                  }
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={delete_icon}
                                    alt="Delete"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => setMoreVertOpen(false)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={close_icon}
                                    alt="Close"
                                  />
                                </button>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {/* Add another payment method button */}
        {isDropdownOpen && (
          <button
            className="custom-button-addpayment mt-5 font-poppins mx-10 mb-5"
            onClick={onAddPaymentMethod}
          >
            <img
              src={add_icon}
              alt="Add"
              className="inline-block font-poppins mr-2 w-6 h-6"
            />
            <span className="custom-text-addpayment font-semibold">
              Add another payment method
            </span>
          </button>
        )}

        <div
          style={{
            borderTop: "2px solid #D9D9D9",
            marginLeft: 10,
            marginRight: 10,
            marginTop: 10,
          }}
        ></div>

        {/* Selected payment index */}
        {getSelectedPaymentfromStore &&
          getSelectedPaymentfromStore?.paymentType === "CC" && (
            <>
              {/* Billing address selection */}
              <div className="flex items-center justify-between mt-5 mx-2">
                <p className="payment-heading-list font-poppins font-semibold">
                  Select or add billing address.
                </p>
                <div className="image-container">
                  <img
                    className="inner-image"
                    src={dropdown_arrow}
                    alt="Inner Dropdown Arrow"
                  />
                  <img
                    className="dropdown-img cursor-pointer"
                    src={
                      isBillingDropdownOpen ? arrow_open : dropdown_arrow_close
                    }
                    alt="Dropdown"
                    onClick={toggleBillingAddress}
                  />
                </div>
              </div>

              {/* Display selected billing address when dropdown is closed */}
              {!isBillingDropdownOpen &&
                getSelectedBillingfromStore &&
                getSelectedBillingfromStore != null && (
                  <div
                    className="mt-3 border rounded-lg p-2 flex items-center justify-between"
                    style={{
                      height: "65px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="flex items-center space-x-2 p-2">
                      <span
                        className="text-[#4E4E4E] ml-4"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                      >
                        {getSelectedBillingfromStore?.firstName}{" "}
                        {getSelectedBillingfromStore?.lastName},{" "}
                        {getSelectedBillingfromStore?.address1}
                        <br />
                        <span
                          className="text-[#4E4E4E] font-poppins font-semibold"
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "11px",
                            lineHeight: "22px",
                          }}
                        >
                          {getSelectedBillingfromStore.city}{" "}
                          {getSelectedBillingfromStore.state},{" "}
                          {getSelectedBillingfromStore.zipCode}, US
                        </span>
                      </span>
                    </div>
                  </div>
                )}

              {/* Billing address details when dropdown is open */}
              {isBillingDropdownOpen && billingAddresses.length > 0 && (
                <div className="mb-2 mt-2 max-h-30 overflow-y-auto">
                  {billingAddresses.map((address, index) => {
                    const isOptionsVisible = visibleOptionsIndex === index;
                    const isSelected =
                      getSelectedBillingfromStore?.consumerAddressId ===
                      address?.consumerAddressId;
                    return (
                      <div
                        key={index}
                        className="bg-[#D9D9D9] mt-3 border rounded-lg p-2 flex items-center justify-between"
                        style={{
                          height: "65px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <div className="flex items-center space-x-2 p-2">
                          <div
                            onClick={() =>
                              handleAddressSelection(
                                index,
                                getSelectedPaymentfromStore
                              )
                            }
                            className="form-radio-outer"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                              border: "2px solid #ffffff",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#ffffff",
                              justifyContent: "center",
                              transition: "width 0.2s, height 0.2s",
                            }}
                          >
                            <div
                              style={{
                                width:
                                  selectedAddressIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                height:
                                  selectedAddressIndex === index || isSelected
                                    ? "8px"
                                    : "0",
                                borderRadius: "50%",
                                backgroundColor: "#6B46C1",
                                transition: "width 0.2s, height 0.2s",
                              }}
                            />
                          </div>
                          <span
                            className="text-[#4E4E4E] ml-4"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                            onClick={() => handleAddressClick(index)}
                          >
                            {address.firstName} {address.lastName},{" "}
                            {address.address1}
                            <br />
                            <span
                              className="text-[#4E4E4E] font-poppins font-semibold"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "11px",
                                lineHeight: "22px",
                              }}
                            >
                              {address.city}, {address.state}, {address.zipCode}
                              ,{address?.country}.
                            </span>
                          </span>
                        </div>

                        <div className="flex items-center space-x-2">
                          {isOptionsVisible ? (
                            <>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={() => handleEditBillingAddress(index)}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={edit_icon}
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeletePaymentMethod(index);
                                }}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={delete_icon}
                                  alt="Delete"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={handleCloseOptions}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={close_icon}
                                  alt="Close"
                                />
                              </button>
                            </>
                          ) : (
                            <button
                              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                              onClick={() => handleMoreOptionsClick(index)}
                            >
                              <img
                                className="w-6 h-6 cursor-pointer"
                                src={more_vert}
                                alt="More Options"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Add a billing address button */}
              {getSelectedPaymentfromStore &&
                getSelectedPaymentfromStore?.paymentType === "CC" &&
                isBillingDropdownOpen && (
                  <button
                    className="custom-button-addpayment mt-3 font-poppins mx-10 mb-5"
                    onClick={addBilling}
                  >
                    <img
                      src={add_icon}
                      alt="Add"
                      className="inline-block font-poppins mr-2 w-6 h-6"
                    />
                    <span className="custom-text-addpayment font-semibold">
                      Add a billing address
                    </span>
                  </button>
                )}

              <div
                style={{
                  borderTop: "2px solid #D9D9D9",
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                }}
              ></div>

              {showConfirmDialog && (
                <ConfirmDialog
                  title="Delete Billing Address"
                  message="Are you sure you want to delete this billing address?"
                  onCancel={() => {
                    setShowConfirmDialog(false);
                    setDeletePaymentIndex(null);
                  }}
                  onConfirm={() => {
                    // Handle confirm logic here

                    handleConfirmDelete();
                    setShowConfirmDialog(false); // Close dialog after action
                  }}
                  isOpen={showConfirmDialog}
                />
              )}
            </>
          )}

        {showDialogCVV && (
          <ConfirmDialogCVV
            title="ADd CVV"
            message="Please Enter Cvv"
            onCancel={() => setShowDialogCVV(false)}
            onConfirm={handleConfirmCVV} // Ensure this function is called on confirmation
            isOpen={showDialogCVV}
          />
        )}

        {showConfirmDialogPayment && (
          <ConfirmDialog
            title="Delete Payment Method"
            message="Are you sure you want to delete this payment method?"
            onCancel={() => setShowConfirmDialogPayment(false)}
            onConfirm={handleConfirmDeletePaymet} // Ensure this function is called on confirmation
            isOpen={showConfirmDialogPayment}
          />
        )}

        {subscriptionPlanData &&
          ((getSelectedPaymentfromStore?.paymentType === "CC" &&
            getSelectedPaymentfromStore != null &&
            getSelectedBillingfromStore != null) ||
            (getSelectedPaymentfromStore?.paymentType === "ACH" &&
              getSelectedPaymentfromStore != null)) && (
            <div className="mt-6 mb-20">
              <h2 className="text-gray-700 text-[20px] leading-6 mb-4 font-poppins font-bold ml-2">
                Review & Pay
              </h2>
              <div className="flex items-center justify-between rounded-lg">
                <div className="w-16 h-16 p-2 rounded-12">
                  <img
                    onClick={handleReviewAndPayClick}
                    className="w-full h-full cursor-pointer"
                    src={
                      subscriptionPlanData?.pageData?.subscriptionIcon
                        ? subscriptionPlanData?.pageData?.subscriptionIcon
                        : ""
                    }
                    // src={subscriptionPlanData?.pageData?.subscriptionIcon}
                  />
                </div>
                <div className="flex-1 text-left ml-2">
                  <p
                    onClick={handleReviewAndPayClick}
                    className="text-[#4E4E4E] font-semibold font-poppins text-[14px] leading-4 text-left"
                    style={{
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    {fintechName}
                    {/* {subscriptionPlanData
                      ? subscriptionPlanData.pageData.description ===
                        "Achieve more with GUAC Gold!"
                        ? "Guac"
                        : "One Goal"
                      : "No subscription plan selected"} */}
                  </p>

                  <p
                    onClick={handleReviewAndPayClick}
                    className="text-[#4E4E4E] font-poppins text-[12px] font-medium leading-4 mt-1"
                    style={{
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  >
                    {subscriptionPlanData?.pageData?.subscriptionTitle}
                    {/* {subscriptionPlanData
                      ? subscriptionPlanData.pageData.subscriptionTitle ===
                        "Gold Subscription"
                        ? "Gold Subscription"
                        : subscriptionPlanData.pageData.subscriptionTitle ===
                            "Gold"
                          ? "Gold Subscription"
                          : subscriptionPlanData.pageData.subscriptionTitle ===
                              "Platinum"
                            ? "Platinum Subscription"
                            : `${subscriptionPlanData.pageData?.billingCycle} Subscription`
                      : "No subscription plan selected"} */}
                  </p>
                </div>
                <div className="text-right">
                  <p
                    className="text-[#4E4E4E] font-poppins font-semibold text-[14px]"
                    style={{
                      fontWeight: 600,
                      lineHeight: "20px",
                      fontSize: "14px",
                    }}
                  >
                    ${subscriptionPlanData.pageData.recurringAmount}
                  </p>
                  <p
                    className="text-[#4E4E4E] leading-6 text-[12px] font-poppins"
                    style={{
                      fontWeight: 500,
                      lineHeight: "20px",
                      fontSize: "12px",
                    }}
                  >
                    /
                    {subscriptionPlanData
                      ? subscriptionPlanData.pageData?.billingCycle ===
                        "Annually"
                        ? "Year"
                        : "Month"
                      : "No subscription plan selected"}
                  </p>
                </div>
              </div>

              {/* Approve Payment Button */}

              <div className="fixed bottom-0 left-0 right-0 p-4">
                <button
                  onClick={() =>
                    handleapp(
                      getSelectedBillingfromStore,
                      getSelectedPaymentfromStore
                    )
                  }
                  className="w-full bg-[#705AE2] text-white py-4 rounded-lg mt-6 transition duration-300 focus:outline-none focus:ring-2 font-poppins font-bold shadow-md"
                >
                  APPROVE PAYMENT
                </button>
              </div>
            </div>
          )}

        {/* Debugging Information */}
      </div>
    </div>
  );
};

export default ListPayments;
