import React, { useState, useEffect, useCallback } from "react";

import arrow_back_ios_new from "../../../assets/Images/arrow_back_ios_new.png";
import add_card from "../../../assets/Images/add_card.png";
import add_card_white from "../../../assets/Images/add_card_white.png";
import account_balance_black from "../../../assets/Images/account_balance_black.svg";
import add_card_black from "../../../assets/Images/add_card_black.svg";

import account_balance_white from "../../../assets/Images/account_balance_white.png";
import account_balance from "../../../assets/Images/account_balance.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeApiCall } from "../../../services/ApiService";
import {
  API_CALL_TYPE,
  POST_PAYMENT_METHOD,
  UPDATE_SESSION,
} from "../../../services/Api";
import LoaderComponent from "../../../components/LoaderComponent";
import { ToastContentProps, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setSessionData,
  setLoading,
  setError,
  SessionState,
} from "../../../redux/sessionSlice";
import {
  PaymentMethod,
  SessionData,
  SessionUpdateRequest,
} from "../../../types/types";
import { Config } from "../../../Utils/AppConstants";
import { useLocation } from "react-router-dom";
import "./AddCreditDebitCardScreen.css"; // Import your CSS file for animations

export default function AddCreditDebitCardScreen() {
  const queryClient = useQueryClient();
  const [focused, setFocused] = useState(false); // State to track if input is focused
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const location = useLocation();
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );
  const { state } = location;
  const paymentMethods = state?.payment;
  const [formData, setFormData] = useState<{
    isDefault: boolean;
    paymentMethodName: string | null;
    paymentType: string;
    routingNumber: string | null;
    accountNumber: string | null;
    ACHName: string | null;
    cardType: string | null;
    firstName: string | null;
    middleName: string;
    lastName: string;
    expirationDate: Date | null;
    CardNumber: string;
    CardHolderName: string;
    CVV: string;
    Nickname: string;
  }>({
    isDefault: true,
    paymentMethodName: null,
    paymentType: "",
    routingNumber: null,
    accountNumber: null,
    ACHName: null,
    cardType: null,
    firstName: null,
    middleName: "",
    lastName: "",
    expirationDate: null,
    CardNumber: "",
    CardHolderName: "",
    CVV: "",
    Nickname: "",
  });

  const [errors, setErrors] = useState<{
    CardNumber: string | null;
    expirationDate: string | null;
    CardHolderName: string | null;
    CVV: string | null;
    accountNumber: string | null;
    routingNumber: string | null;
    Nickname: string | null; // Add this line
  }>({
    CardNumber: null,
    expirationDate: null,
    CardHolderName: null,
    CVV: null,
    accountNumber: null,
    routingNumber: null,
    Nickname: null, // Add this line
  });

  const [errors1, setErrors1] = useState<{
    accountNumber: string | null;
    routingNumber: string | null;
  }>({
    accountNumber: null,
    routingNumber: null,
  });

  const resetErrors = () => {
    setErrors({
      CardNumber: null,
      expirationDate: null,
      CardHolderName: null,
      CVV: null,
      accountNumber: null,
      routingNumber: null,
      Nickname: null, // Add this line
    });

    setErrors1({
      accountNumber: null,
      routingNumber: null,
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const minDate = new Date();
  const [showLoader, setShowLoader] = useState(false); // State to control the visibility of the loader
  const [isCVVMasked, setIsCVVMasked] = useState(true);
  const dispatch = useDispatch();
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };
  const toggleCVVMask = () => {
    setIsCVVMasked((prev) => !prev);
  };

  useEffect(() => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData?.data?.sessionPages;

      if (!sessionPages || sessionPages.length === 0) {
        // If sessionPages array is empty or not found, fetch PaymentMethodList from API
        // fetchPaymentMethods();
        // fetchBillingAddresses();
        return;
      }

      sessionPages.forEach((page: { pageName: string; pageData: string }) => {
        try {
          if (page.pageName === "SUBSCRIPTION PLAN") {
            const parsedPageData = JSON.parse(
              page?.pageData.replace(/\\/g, "")
            );

            if (parsedPageData) {
              if (parsedPageData?.asaFintechCode !== null) {
                const parsedFicode = parseInt(
                  parsedPageData?.asaFintechCode,
                  10
                );
                if (!isNaN(parsedFicode)) {
                  Config.asaFicode = parsedFicode;
                } else {
                  // Handle case where asaFicode is not a valid number
                }
              }
            }
          }
        } catch (error) {}
      });

      Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
      Config.asaConsumerCode = parseInt(
        UpdatedLocalData?.data?.asaConsumerCode
      );

      const versionCode = UpdatedLocalData?.data?.version;
      Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");

      queryClient.setQueryData("sessionID", Config.sessionID);
    }

    if (location.state && location.state.payment) {
      const paymentMethod = location.state.payment;

      // Determine the payment type and set selectedOption accordingly
      const paymentType = paymentMethod.paymentType === "CC" ? "card" : "ACH";
      setSelectedOption(paymentType);
      let formattedCardNumber = paymentMethod.cardNumber
        ? paymentMethod.cardNumber.replace(/-/g, "").slice(0, 16)
        : null;

      if (formattedCardNumber) {
        formattedCardNumber =
          formattedCardNumber.match(new RegExp(".{1,4}", "g"))?.join("-") || "";
      }
      setFormData({
        isDefault: paymentMethod.isDefault,
        paymentMethodName: paymentMethod.paymentMethodName,
        paymentType: paymentMethod.paymentType,
        routingNumber:
          paymentMethod.paymentType === "CC"
            ? null
            : paymentMethod.routingNumber,
        accountNumber:
          paymentMethod.paymentType === "CC"
            ? null
            : paymentMethod.accountNumber,
        ACHName: paymentMethod.bankName,
        cardType: paymentMethod.cardType,
        firstName: paymentMethod.firstName,
        middleName: paymentMethod.middleName,
        lastName: paymentMethod.lastName,
        expirationDate: new Date(
          Number(paymentMethod.expirationYear),
          Number(paymentMethod.expirationMonth) - 1,
          1 // Day is set to 1 assuming you don't have exact day information
        ),
        CardNumber: formattedCardNumber, // Assuming you want to mask the card number
        CardHolderName: paymentMethod.cardHolderName,
        CVV: "", // CVV is usually not stored, so keep it empty
        Nickname: "",
      });
    }
  }, [location.state]);

  const validateForm = () => {
    const newErrors = {
      CardNumber:
        selectedOption === "card" && !formData.CardNumber
          ? "Card Number is required"
          : null,
      expirationDate: formData.expirationDate
        ? null
        : "Expiration Date is required",
      CardHolderName: formData.CardHolderName
        ? null
        : "Cardholder Name is required",
      CVV:
        selectedOption === "card" && !formData.CVV ? "CVV is required" : null,
      accountNumber:
        selectedOption === "ACH" && !formData.accountNumber
          ? "Account Number is required"
          : null,
      routingNumber:
        selectedOption === "ACH" && !formData.routingNumber
          ? "Routing Number is required"
          : null,
      Nickname: formData.Nickname ? null : null, // Add validation for Nickname
    };

    if (formData.CVV && !/^\d{3,4}$/.test(formData.CVV)) {
      newErrors.CVV = "CVV should be 3 or 4 digits";
    }

    if (
      selectedOption === "ACH" &&
      formData.accountNumber &&
      !/^\d{10}$/.test(formData.accountNumber)
    ) {
      newErrors.accountNumber = "Account Number should be 10 digits";
    }

    if (
      selectedOption === "ACH" &&
      formData.routingNumber &&
      !/^\d{9}$/.test(formData.routingNumber)
    ) {
      newErrors.routingNumber = "Routing Number should be 9 digits";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === null);
  };

  const validateFormInputs = () => {
    const newErrors = {
      accountNumber:
        selectedOption === "ACH" && !formData.accountNumber
          ? "Account Number is required"
          : null,
      routingNumber:
        selectedOption === "ACH" && !formData.routingNumber
          ? "Routing Number is required"
          : null,
    };

    // Custom validations

    if (
      selectedOption === "ACH" &&
      formData.accountNumber &&
      !/^\d{10}$/.test(formData.accountNumber)
    ) {
      newErrors.accountNumber = "Account Number should be 10 digits";
    }

    if (
      selectedOption === "ACH" &&
      formData.routingNumber &&
      !/^\d{9}$/.test(formData.routingNumber)
    ) {
      newErrors.routingNumber = "Routing Number should be 9 digits";
    }

    // Log errors for debugging

    setErrors1(newErrors);
    return Object.values(newErrors).every((error) => error === null);
  };

  const updateSessionResponse = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,
      asaConsumerCode: UpdatedLocalData?.data?.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      throw error;
    }
  };

  const handlePaymentResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    try {
      // Set loading state to true before making the API call

      await updateSessionResponse(
        updatedsessionData,
        formattedPaymentMethods,
        "3",
        "PaymentMethodList",
        "PaymentMethodList",
        "/PaymentList",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  const handleApiError = (error: any) => {};

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handlePaymentMethodResponse = async (response: any) => {
    // dispatch(setSelectedPaymentMethod(response?.data));
    // Handle success response
    setIsLoading(false);

    if (response && response?.status == "400") {
      // Handle the invalid session error here
      showCustomToast(response?.message, true);
    } else {
      // Handle other responses or success cases

      showCustomToast(response?.message, false);

      // Dispatch actions, update state, etc.
    }

    fetchPaymentMethods();
  };

  const updateSession = async (session_if_any: any) => {
    dispatch(setLoading(true));
    setIsLoading(true);

    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-PORTFOLIO",
      pageCount: "5",

      status: "Incomplete",
      statusMessage: "COMPLETED",
      sessionPages: [
        {
          asaSessionCode: UpdatedLocalData?.data?.asaSessionCode,

          pageNumber: "3", // Page number, could be a string or a number depending on your API requirements
          pageName: "PaymentMethodList", // Name of the page (e.g., "Landing")
          pageTitle: "PaymentMethodList", // Title of the page (e.g., "Select Package")
          pageDescription: "PaymentMethodList", // Description of the page
          pageUrl: "./savePayments", // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: "Completed", // Status message for the page
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };

  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      //    navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      navigate("/portfoliopilot");
      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const handlePaymentMethodError = (error: any) => {
    // Handle error
    setIsLoading(false);
    showCustomToast(error?.message, true);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedOption == "ACH") {
      if (!validateFormInputs()) {
        return; // If validation fails, prevent form submission
      }
    } else {
      if (!validateForm()) {
        return; // If validation fails, prevent form submission
      }
    }

    setIsLoading(true);

    const isCardSelected = selectedOption === "card";
    const paymentType = isCardSelected ? "CC" : "ACH";

    const expirationMonth =
      isCardSelected && formData.expirationDate
        ? String(formData.expirationDate.getMonth() + 1).padStart(2, "0")
        : null;
    const expirationYear =
      isCardSelected && formData.expirationDate
        ? String(formData.expirationDate.getFullYear())
        : null;

    const processFormData = (data: any) => {
      const processedData: any = {};
      for (const key in data) {
        if (data[key] === "") {
          processedData[key] = null;
        } else {
          processedData[key] = data[key];
        }
      }
      return processedData;
    };

    // Remove hyphens from CardNumber if present
    const cardNumberWithoutHyphens = formData.CardNumber?.replace(/-/g, "");

    const dataToSend = {
      ...processFormData(formData),
      paymentMethodID: location?.state?.payment?.paymentMethodID, // Include the paymentMethodID if it's an update
      paymentType: paymentType,
      expirationMonth: expirationMonth,
      expirationYear: expirationYear,
      expirationDate: undefined,
      CardNumber: selectedOption == "ACH" ? null : cardNumberWithoutHyphens, // Send CardNumber without hyphens
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.POST_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodResponse,
        handlePaymentMethodError,
        "",
        "",
        dataToSend
      );

      // Handle success - maybe show a success message to the user
    } catch (error) {
      // Handle error - maybe show an error message to the user
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let value = event.target.value;

    // Update form data with raw input
    if (/^[0-9]*$/.test(value) || value === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value === "" ? null : value,
      }));
    }

    // Format card number with hyphens for readability
    if (fieldName === "CardNumber") {
      // Remove existing hyphens and limit to 16 characters
      value = value.replace(/-/g, "").slice(0, 16);

      // Add hyphens every 4 characters
      if (value.length > 0) {
        value = value.match(/.{1,4}/g)?.join("-") || "";
      }
    }

    // Update form data with formatted value
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value === "" ? null : value,
    }));

    // Handle error validation message
    setErrors((prevErrors) => {
      const digitsOnly = value.replace(/-/g, ""); // Remove hyphens to count digits
      let errorMessage = null;

      if (fieldName === "CardNumber") {
        // Regex to allow both 15 and 16 digit formats
        const cardNumberRegex =
          /^(\d{4}-\d{4}-\d{4}-\d{4}|\d{4}-\d{4}-\d{4}-\d{3})$/;

        if (!cardNumberRegex.test(value)) {
          errorMessage =
            "Invalid Card Number format. It should be 15 or 16 digits long.";
        }
      } else if (value === "") {
        errorMessage = `${fieldName} is required`;
      }

      return {
        ...prevErrors,
        [fieldName]: errorMessage,
      };
    });
  };

  const handleInputChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let value = event.target.value;

    // If the field is CardNumber, add hyphens for user readability
    if (fieldName === "CardNumber") {
      // Remove existing hyphens and limit to 16 characters
      value = value.replace(/-/g, "").slice(0, 16);

      // Add hyphens for every 4 characters
      if (value.length > 0) {
        value = value.match(new RegExp(".{1,4}", "g"))?.join("-") || "";
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value === "" ? null : value,
    }));

    // Handle error validation message
    setErrors1((prevErrors) => ({
      ...prevErrors,
      [fieldName]: value === "" ? `${fieldName} is required` : null,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      expirationDate: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expirationDate: date ? null : "Expiration Date is required",
    }));
  };

  const handleBackClick = () => {
    navigate(-1); // This will navigate back to the previous page in the browser history
  };

  const clearFields = () => {
    setFormData({
      isDefault: true,
      paymentMethodName: null,
      paymentType: "",
      routingNumber: null,
      accountNumber: null,
      ACHName: null,
      cardType: null,
      firstName: null,
      middleName: "",
      lastName: "",
      expirationDate: null,
      CardNumber: "",
      CardHolderName: "",
      CVV: "",
      Nickname: "",
    });
  };

  return (
    <div className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col items-center text-center pt-10 relative font-poppins">
      {isLoading && <LoaderComponent />}{" "}
      <div className="absolute top-4 left-4 rounded-lg p-1 w-[40px] h-[40px] flex items-center justify-center">
        <img
          className="cursor-pointer w-[18px] h-[18px] mt-8"
          src={arrow_back_ios_new}
          alt="Back"
          onClick={handleBackClick}
        />
      </div>
      <h1 className="text-white font-bold text-xl">
        {selectedOption === "" && "Select a Payment Method"}
        {selectedOption === "card" && "Add a Credit or Debit Card"}
        {selectedOption === "ACH" && "Add a Checking Account"}
      </h1>
      <div className="bg-[#121212] rounded-t-3xl shadow-md mt-10 w-full max-w-md p-6 flex-grow flex flex-col">
        <div className="flex justify-center mb-6 space-x-4">
          <div
            className={`flex-1 p-2 border-2 h-[71px] flex items-center justify-center ${
              selectedOption === "card"
                ? "border-4 border-[#705AE2] bg-[#705AE2]"
                : selectedOption === ""
                  ? "border-white bg-[#121212]"
                  : "border-[#767678] bg-[#121212]"
            } rounded-lg cursor-pointer`}
            onClick={() => {
              setSelectedOption("card");
              clearFields();
              resetErrors();
            }}
          >
            <img
              src={
                selectedOption === "card"
                  ? add_card
                  : selectedOption === ""
                    ? add_card_white // Replace with the path to the image for "AnotherOption"
                    : add_card_black
              }
              className={`w-8 h-8 ${
                selectedOption === "card" ? "filter invert" : "text-black"
              }`}
              alt="Card"
            />
          </div>
          <div
            className={`flex-1 p-2 border-2 h-[71px] flex items-center justify-center ${
              selectedOption === "ACH"
                ? "border-4 border-[#705AE2] bg-[#705AE2]"
                : selectedOption === ""
                  ? "border-white bg-[#121212]"
                  : "border-[#767678] bg-[#121212]"
            } rounded-lg cursor-pointer`}
            onClick={() => {
              setSelectedOption("ACH");
              clearFields();
              resetErrors();
            }}
          >
            <img
              src={
                selectedOption === "ACH"
                  ? account_balance
                  : selectedOption === ""
                    ? account_balance_white // Replace with the path to the image for "AnotherOption"
                    : account_balance_black
              }
              className={`w-8 h-8 ${
                selectedOption === "ACH" ? "filter invert" : "text-black"
              }`}
              alt="Bank"
            />
          </div>
        </div>

        {selectedOption && (
          <div>
            {selectedOption === "card" && (
              <form onSubmit={handleFormSubmit}>
                <div className="mb-10 relative">
                  <label
                    className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                      formData.CardNumber
                        ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                        : errors.CardNumber
                          ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                          : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                    }`}
                    htmlFor="CardNumber"
                    style={{
                      fontSize: errors.CardNumber
                        ? "1rem"
                        : formData.CardNumber
                          ? "1.25rem"
                          : "1rem",
                    }}
                  >
                    Card Number
                  </label>
                  <input
                    type="tel"
                    id="CardNumber"
                    className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-white shadow-sm focus:border-white focus:ring-white sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                      formData.CardNumber ? "text-[#FFFFFF]" : ""
                    }`}
                    style={{
                      backgroundColor: "#121212",
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                    }}
                    value={formData.CardNumber || ""}
                    onChange={(e) => handleInputChange(e, "CardNumber")}
                  />
                  {errors.CardNumber && (
                    <p
                      className="text-red-500 text-left text-xs absolute"
                      style={{ left: "10px" }}
                    >
                      {errors.CardNumber}
                    </p>
                  )}
                </div>

                <div className="flex space-x-4 mb-8">
                  <div className=" custom-date-picker-port relative mb-4 flex-1">
                    <label
                      className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                        formData.expirationDate
                          ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                          : errors.expirationDate
                            ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                            : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                      }`}
                      htmlFor="expirationDate"
                      style={{
                        fontSize: errors.expirationDate
                          ? "1rem"
                          : formData.expirationDate
                            ? "1.25rem"
                            : "1rem",
                        zIndex: 1, // Ensure label is above DatePicker
                      }}
                    >
                      Expiration
                    </label>
                    <DatePicker
                      id="expirationDate"
                      selected={formData.expirationDate}
                      onChange={handleDateChange}
                      minDate={minDate}
                      dateFormat="MM/yyyy"
                      placeholderText=""
                      className={` custom-date-picker mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-white shadow-sm focus:border-white focus:ring-white sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                        formData.expirationDate ? "text-[#FFFFFF]" : ""
                      } bg-[#121212]`}
                      showMonthYearPicker
                    />
                    {errors.expirationDate && (
                      <p
                        className="text-red-500 text-left text-xs absolute"
                        style={{ left: "10px" }}
                      >
                        {errors.expirationDate}
                      </p>
                    )}
                  </div>

                  <div className="relative mb-4 flex-1">
                    <label
                      className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                        formData.CVV
                          ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                          : errors.CVV
                            ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                            : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                      }`}
                      htmlFor="CVV"
                      style={{
                        fontSize: errors.CVV
                          ? "1rem"
                          : formData.CVV
                            ? "1.25rem"
                            : "1rem",
                        zIndex: 1, // Ensure the label is above the input
                      }}
                    >
                      CVV
                    </label>
                    <input
                      type="password"
                      id="CVV"
                      inputMode="numeric"
                      placeholder=""
                      className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-white shadow-sm focus:border-white focus:ring-white sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                        formData.CVV ? "text-[#FFFFFF]" : ""
                      }`}
                      style={{
                        backgroundColor: "#121212",
                        borderColor: "#FFFFFF",
                        color: "#FFFFFF",
                        zIndex: 0, // Ensure the input is below the label
                      }}
                      value={formData.CVV || ""}
                      onChange={(e) => handleInputChange(e, "CVV")}
                    />
                    {errors.CVV && (
                      <p
                        className="text-red-500 text-xs absolute"
                        style={{ left: "10px" }}
                      >
                        {errors.CVV}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-8 relative">
                  <label
                    className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                      formData.CardHolderName
                        ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                        : errors.CardHolderName
                          ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                          : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                    }`}
                    htmlFor="CardHolderName"
                    style={{
                      fontSize: errors.CardHolderName
                        ? "1rem"
                        : formData.CardHolderName
                          ? "1.25rem"
                          : "1rem",
                    }}
                  >
                    Cardholder Name
                  </label>
                  <input
                    type="text"
                    id="CardHolderName"
                    className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                      formData.CardHolderName ? "text-[#4E4E4E]" : ""
                    }`}
                    style={{
                      backgroundColor: "#121212",
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                    }}
                    value={formData.CardHolderName || ""}
                    onChange={(e) => handleInputChange(e, "CardHolderName")}
                    placeholder=""
                  />
                  {errors.CardHolderName && (
                    <p
                      className="text-red-500 text-left text-xs absolute"
                      style={{ left: "10px" }}
                    >
                      {errors.CardHolderName}
                    </p>
                  )}
                </div>

                <div className="mb-4 relative">
                  <label
                    className={`absolute transition-all font-poppins duration-300 ease-in-out transform left-3 CF bg-[#121212] px-1 ${
                      formData.Nickname
                        ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                        : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                    }`}
                    htmlFor="Nickname"
                    style={{
                      fontSize: formData.Nickname ? "1.25rem" : "0.875rem",
                      color: formData.Nickname ? "#FFFFFF" : "#FFFFFF",
                    }}
                  >
                    Nickname (optional)
                  </label>
                  <input
                    type="text"
                    id="Nickname"
                    className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-[#EBECF0] shadow-sm focus:border-[#705AE2] focus:ring-2 focus:ring-[#705AE2] sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                      formData.Nickname
                        ? "text-[#FFFFFF] border-[#FFFFFF]"
                        : "text-white"
                    }`}
                    style={{
                      backgroundColor: "#121212",
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                    }}
                    value={formData.Nickname || ""}
                    onChange={(e) => handleInputChange(e, "Nickname")}
                    placeholder=""
                  />
                </div>

                <button className="w-full bg-[#705AE2] text-white py-3 font-poppins font-semibold rounded-md  transition duration-300 focus:outline-none focus:ring-2  shadow-custom">
                  {paymentMethods ? "UPDATE" : "SAVE"}
                </button>
              </form>
            )}
            {selectedOption === "ACH" && (
              <form onSubmit={handleFormSubmit}>
                <div className="mb-10 relative">
                  <label
                    className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                      formData.accountNumber
                        ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                        : errors1.accountNumber
                          ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                          : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                    }`}
                    htmlFor="accountNumber"
                    style={{
                      fontSize: errors1.accountNumber
                        ? "1rem"
                        : formData.accountNumber
                          ? "1.25rem"
                          : "1rem",
                    }}
                  >
                    Account Number
                  </label>
                  <input
                    type="tel"
                    id="accountNumber"
                    className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                      formData.accountNumber ? "text-[#FFFFFF]" : ""
                    }`}
                    style={{
                      backgroundColor: "#121212",
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                    }}
                    value={formData.accountNumber || ""}
                    onChange={(e) => handleInputChange1(e, "accountNumber")}
                  />
                  {errors1.accountNumber && (
                    <p
                      className="text-red-500 text-left text-xs absolute"
                      style={{ left: "10px" }}
                    >
                      {errors1.accountNumber}
                    </p>
                  )}
                </div>

                <div className="mb-10 relative">
                  <label
                    className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
                      formData.routingNumber
                        ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
                        : errors1.routingNumber
                          ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                          : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
                    }`}
                    htmlFor="routingNumber"
                    style={{
                      fontSize: errors1.routingNumber
                        ? "1rem"
                        : formData.routingNumber
                          ? "1.25rem"
                          : "1rem",
                    }}
                  >
                    Routing Number
                  </label>
                  <input
                    type="tel"
                    id="routingNumber"
                    className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                      formData.routingNumber ? "text-[#FFFFFF]" : ""
                    }`}
                    style={{
                      backgroundColor: "#121212",
                      borderColor: "#FFFFFF",
                      color: "#FFFFFF",
                    }}
                    value={formData.routingNumber || ""}
                    onChange={(e) => handleInputChange1(e, "routingNumber")}
                  />
                  {errors1.routingNumber && (
                    <p
                      className="text-red-500 text-left text-xs absolute"
                      style={{ left: "10px" }}
                    >
                      {errors1.routingNumber}
                    </p>
                  )}
                </div>
                <button className="w-full bg-[#705AE2] text-white py-2 rounded-md  transition duration-300 focus:outline-none focus:ring-2 ">
                  {paymentMethods ? "UPDATE" : "SAVE"}
                </button>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
