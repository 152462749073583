import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import asavault_logo from "../assets/Images/asavaultlogo.png";
import "../assets/css/style.css";
import { useSelector } from "react-redux";
import { SessionState } from "../redux/sessionSlice";
export default function SessionTokenValidate() {
  const location = useLocation();
  const passedData = location.state;
  const dynamicLink = useSelector(
    (state: { session: SessionState }) => state.session.dynamicLink
  );
  useEffect(() => {}, []);
  const handleBackButtonClick = () => {
    // Go back to the ASAVAULT APP using the dynamic link
    if (dynamicLink) {
      window.location.href = dynamicLink;
    } else {
      window.location.href = "https://asavault.page.link/crbError";
    }
  };

  return (
    <div className="flex bg-theme asavault_logo  flex-col items-center justify-center h-screen">
      <img src={asavault_logo} alt="ASA Vault Logo" className="h-16 mb-4" />

      <div className="flex flex-col items-center justify-center mt-24">
        <p className="text-sm  font-medium poppins-regular  text-white mb-4 text-center lg:mx-auto lg:px-4">
          {passedData == null
            ? "Authentication Error, Please close the tab and try AsaVault App."
            : "Authentication Error, Missing Authentication information. Please close the tab and try AsaVault App."}
        </p>
        <button
          onClick={handleBackButtonClick}
          className="bg-white poppins-semibold text-purple-600 py-2 px-4 rounded-md shadow-md hover:bg-purple-600 hover:text-white transition duration-300"
        >
          Back to ASAVAULT APP
        </button>
      </div>
    </div>
  );
}
